
import React from 'react';
import { Routes, Route, Link, Navigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { Divider, FormLabel } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Input from '@mui/material/Input';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import EmojiTransportationOutlinedIcon from '@mui/icons-material/EmojiTransportationOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect, useRef } from "react";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import dummyProfilePic from "../../assets/images/dummyimg.jpg";
import { borderColor } from '@mui/system';
import { baseURL, getUserByAuthId, getUserByEmail } from '../../Repository/Api';
import { getFirebaseAuthModule } from '../../Repository/Authentication/Firebase';
import FormControl from '@mui/material/FormControl';
import MuiAlert from '@mui/material/Alert';
import { validateProfileForm } from '../../Business/AuthenticationBusiness';
import { storageModule } from "../../Repository/Authentication/Firebase";
import { ref, uploadBytes, getDownloadURL, getStorage, uploadString } from "firebase/storage";
import MuiPhoneNumber from 'material-ui-phone-number';
import allCountryCodes, { allCountries } from './../../../node_modules/material-ui-phone-number/src/country_data'
import { UpdateUserInfo } from '../../Repository/Api';

function MyProfile() {
    const mainColor = "#981D97";
    const whiteColor = "#fff";
    const blackColor = "#000";
    const inputFieldColor = "#ecedee";
    const paperBgColor = "#f7f8f9"

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
  
    const xlPage = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "400px",
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 15,
        backgroundColor: whiteColor
    };
    const smPage = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };
    const errorText = {
        color: "red",
        fontSize: 13,
        marginTop: 1,
        marginBottom: 1,
    };
    const dummyProfStyle = {
        maxHeight: 150,
        borderRadius: 30,
        maxWidth: 150,
        objectFit: "cover",
    }
    const profileImgContainer = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: 4,
        minHeight: "200px"

    }
    const title = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        // paddingTop: 5,
        // paddingBottom: 25,
        color: mainColor,

    }

    const pageStyle = {
        // minHeight: " calc(100vh - 50px)",
        backgroundColor: "#ce34",
        height: "90%",
        padding: 5,
        backgroundColor: paperBgColor,
    }

    const updateIcon = {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        paddingTop: 3,
        paddingRight: 3,
        color: mainColor,

    }

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText(blackColor),
        backgroundColor: mainColor,
        "&:hover": {
            backgroundColor: "#bf3bbe",
        },
    }));
    const [formValues, setFormValues] = useState({ firstName: "", lastName: "", email: "", countryCode: "", phoneNumber: "", company: "", stdCode: "", userPhoto: "" });
    const [formErrors, setFormErrors] = useState({});
    const [apiError, setApiError] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [dccode, setDCCode] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    function handleOnChange(value, country) {
        // display user input data in UI - mui phone input 

        setFormValues({
            ...formValues,
            "stdCode": country.dialCode != null || undefined ? ('+' + country.dialCode) : (""),
            "phoneNumber": value.replace(/[^0-9]+/g, '').slice(country.dialCode.length) != null || undefined ? value.replace(/[^0-9]+/g, '').slice(country.dialCode.length) : (""),
            "countryCode": country.countryCode != null || undefined ? (country.countryCode) : ("us"),
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    // get user information from DB
    const firstNameApi = apiData.firstName;
    const lastNameApi = apiData.lastName;
    const emailApi = apiData.email;
    const countryCodeApi = apiData.countryCode;
    const phoneNumberApi = apiData.phoneNumber;
    const stdCodeApi = apiData.stdCode;
    const companyApi = apiData.company;
    const userPhotoApi = apiData.userPhoto;

    const handleSubmit = (e) => {
        e.preventDefault();
        const validation = validateProfileForm(formValues);
        setFormErrors(validation.errorList);
        if (validation.status === true) {
            let updateUserData = new FormData();
            // const updateUserData = {
            updateUserData.append('Id', apiData.id);
            updateUserData.append('AuthId', apiData.authId);
            updateUserData.append('SignInMethod', apiData.signInMethod);
            updateUserData.append('CountryCode', formValues.countryCode);
            updateUserData.append("PhoneNumber", formValues.phoneNumber);
            updateUserData.append("stdCode", formValues.stdCode)
            updateUserData.append("FirstName", formValues.firstName);
            updateUserData.append("LastName", formValues.lastName);
            updateUserData.append("Email", formValues.email);
            updateUserData.append("UserTypeId", apiData.userTypeId);
            updateUserData.append("UserRoleId", apiData.userRoleId);
            updateUserData.append("UpdatedBy", apiData.updatedBy);
            updateUserData.append("CreatedBy", apiData.createdBy);
            updateUserData.append("RecordStatus", apiData.recordStatus);
            updateUserData.append("Company", formValues.company);
            updateUserData.append("RegisterStatus", apiData.registerStatus);
            updateUserData.append("UserPhoto", formValues.userPhoto);
            UpdateUserInfo(updateUserData).catch((error) => {

            })
            setIsSubmit(true);
        }
        handleClickAlert();
    }

    
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    useEffect(() => {
        getDistinctCountryCodes();
        const auth = getFirebaseAuthModule();
        auth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken().then((newToken) => {
                    localStorage.setItem("JWTTOKEN", newToken);
                    getFreeCami(user.uid)
                });
            } else {
                return ((error) => { })
            }
        }
        );
    }, []);
    const getFreeCami = async (user) => {
        const res = await getUserByAuthId(user)
            .then(response => {
                setApiData(response);
            }).catch(error => {})

    }
    //display data from API on the UI
    useEffect(() => {
        if (apiData) {

            setFormValues({
                firstName: (firstNameApi != null || undefined ? (firstNameApi) : ("")),
                lastName: lastNameApi != null || undefined ? (lastNameApi) : (""),
                email: emailApi,
                countryCode: countryCodeApi != null || undefined && ((allCountryCodes.includes(countryCodeApi))) ? (countryCodeApi) : (""),
                phoneNumber: phoneNumberApi != null || undefined ? (phoneNumberApi) : (""),
                stdCode: stdCodeApi != null || undefined ? (stdCodeApi) : (""),
                company: companyApi != null || undefined ? (companyApi) : (""),
                userPhoto: userPhotoApi != null || undefined ? (userPhotoApi) : ("")
            });
        }
    }, [apiData]);


    const renderLoginForm = () => {
        if (matches) {
            return (

                <Box elevation={0} style={smPage}>
                    <ThemeProvider theme={theme}>
                        <Container
                            component="main"
                            maxWidth="xs"
                            sx={{
                                width: '100%',
                                marginTop: 8,
                                marginBottom: 8,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <CssBaseline />
                            < >
                                {renderLoginFormContent()}
                            </>
                        </Container>
                    </ThemeProvider>
                </Box>

            );
        } else {
            return (

                <Paper elevation={2} style={xlPage}>
                    <ThemeProvider theme={theme}>
                        <Container
                            component="main"
                            maxWidth="xs"
                            sx={{
                                width: '100%',
                                marginTop: 2,
                                marginBottom: 2,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <CssBaseline />
                            < >
                                {renderLoginFormContent()}
                            </>
                        </Container>
                    </ThemeProvider>
                </Paper>

            );
        }
    };
    const [open, setOpen] = useState(false);

    const handleClickAlert = () => {
        setOpen(true);
    };

    const handleCloseAlert = () => {
        setIsSubmit(false);
        window.location.reload();
    };

    const getDistinctCountryCodes = () => {
        let _distinctCountryCodes = [];
        const _allCountries = allCountryCodes['allCountries'];
        if (Array.isArray(_allCountries)) {
            _allCountries.forEach((value, index) => {
                const _countryCode = [value.iso2];
                const convertedCountry = _countryCode
                _distinctCountryCodes.push(convertedCountry);
            });
        }
        setDCCode(_distinctCountryCodes);
    }

    const renderLoginFormContent = () => {
        if (isSubmit === true) {
            return (
                <>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", height: "60vh", maxWidth: "100%", }}>
                        <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%' }}>
                            Profile Updated!
                        </Alert>
                    </Box>

                </>
            );
        } else {
            return (<Box>{loginFormContent()}</Box>);
        }
    }

    const getDefaultCountry = () => {
        if (dccode.indexOf(formValues['countryCode'].toLowerCase().toString().trim()) > -1) {
            return formValues['countryCode'].toLowerCase().toString().trim();
        } else {
            return 'us';
        }
    }

    const uploadedImage = useRef(null);
    const imageUploader = useRef(null);
    const [imgUrl, setImgUrl] = useState([]);
    const [imageUpload, setImageUpload] = useState(null);
    const storage = getStorage();

    const handleImageUpload = e => {
        // const [imageToUpdate, setImageToUpdate] = useState([]);
        setImageUpload(e.target.files[0])
    };

    function circularIndeterminate() {
        return (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box>
        );
    }

    useEffect(() => {
        setIsLoading(true);
        const uploadImage = () => {
            if (!imageUpload) {
                setIsLoading(false);
            }
            if (imageUpload) {
                const reader = new FileReader();
                const { current } = uploadedImage;
                current.imageUpload = imageUpload;
                reader.onload = e => {
                    current.src = e.target.result;
                }
                reader.readAsDataURL(imageUpload);
                const n = (new Date()).toJSON();
                const imageRef = ref(storage, `Cami-Customer-Images/${n}_${imageUpload.name}`);
                uploadBytes(imageRef, imageUpload).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then((url) => {
                        let payLoad = formValues;
                        payLoad["userPhoto"] = url
                        setFormValues(payLoad);
                        setIsLoading(false)
                    });
                })
            }
        };
        uploadImage();
    }, [imageUpload]);

    const loginFormContent = () => {
        return (
            <form onSubmit={handleSubmit}>
                <Box sx={title}>
                    <Typography variant="h5">Update Profile </Typography>
                </Box>
                <Divider></Divider>
                <Box sx={profileImgContainer}>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        ref={imageUploader}
                        style={{
                            display: "none"
                        }}
                    />
                    {isLoading == true && circularIndeterminate()}
                    {isLoading == false && <img onClick={() => imageUploader.current.click()} style={dummyProfStyle} src={formValues.userPhoto ? (formValues.userPhoto) : (dummyProfilePic)} ref={uploadedImage}></img>}
                </Box>
                <Box>
                    <Input
                        size="small"
                        id="firstName"
                        placeholder="First name"
                        name="firstName"
                        autoComplete="name"
                        autoFocus
                        value={(formValues['firstName']) ? (formValues['firstName']) : ("")}
                        onChange={handleChange}
                        sx={{
                            width: "100%",
                            mt: 2,
                            backgroundColor: inputFieldColor,
                            paddingBlock: 1,
                            paddingInlineStart: 1,
                            paddingInlineEnd: 1,
                            borderRadius: 1,
                        }}
                        startAdornment={
                            <InputAdornment position="start" >
                                <PersonOutlineOutlinedIcon />
                            </InputAdornment>
                        }
                    />
                    {formErrors.firstName && (
                        <p style={errorText}>{formErrors.firstName}</p>
                    )}
                    <Input
                        size="small"
                        id="lastName"
                        placeholder="Last name"
                        name="lastName"
                        autoComplete="last name"
                        autoFocus
                        value={(formValues['lastName']) ? (formValues['lastName']) : ("")}
                        onChange={handleChange}
                        sx={{
                            width: "100%",
                            mt: 2,
                            backgroundColor: inputFieldColor,
                            paddingBlock: 1,
                            paddingInlineStart: 1,
                            paddingInlineEnd: 1,
                            borderRadius: 1,
                        }}
                        startAdornment={
                            <InputAdornment position="start">
                                <PersonOutlineOutlinedIcon />
                            </InputAdornment>
                        }
                    />
                    {formErrors.lastName && (
                        <p style={errorText}>{formErrors.lastName}</p>
                    )}


                    <Box
                        sx={{
                            width: "100%",
                            height: "45px",
                            mt: 2,
                            backgroundColor: inputFieldColor,
                            borderRadius: 1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",


                        }} >
                        <PhoneIcon sx={{
                            alignSelf: "center",
                            backgroundColor: inputFieldColor, margin: 1, color: "#6c6d6d",
                        }} />
                        <MuiPhoneNumber
                            defaultCountry={getDefaultCountry()}
                            value={(formValues['phoneNumber']) ? (`${formValues['stdCode']}${formValues['phoneNumber']}`) : ("")}
                            onChange={handleOnChange}
                            style={{
                                width: "100%",
                                color: "black",
                                backgroundColor: inputFieldColor,
                                paddingBlock: 13,

                            }}
                        />
                    </Box>
                    {formErrors.phoneNumber && (
                        <p style={errorText}>{formErrors.phoneNumber}</p>
                    )}
                    <Input
                        size="small"
                        id="company"
                        placeholder="Company(Optional)"
                        name="company"
                        autoComplete="company"
                        autoFocus
                        value={(formValues['company']) ? (formValues['company']) : ("")}
                        onChange={handleChange}

                        sx={{
                            width: "100%",
                            mt: 2,
                            backgroundColor: inputFieldColor,
                            paddingBlock: 1,
                            paddingInlineStart: 1,
                            paddingInlineEnd: 1,
                            borderRadius: 1,
                        }}
                        startAdornment={
                            <InputAdornment position="start">
                                <   EmojiTransportationOutlinedIcon />
                            </InputAdornment>
                        }
                    />
                    {formErrors.company && (
                        <p style={errorText}>{formErrors.company}</p>
                    )}
                    <Input
                        size="small"
                        id="email"
                        placeholder="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={(formValues['email']) ? (formValues['email']) : ("")}
                        onChange={handleChange}
                        sx={{
                            width: '100%',
                            mt: 2,
                            mb: 2,
                            backgroundColor: inputFieldColor,
                            paddingBlock: 1,
                            paddingInlineStart: 1,
                            paddingInlineEnd: 1,
                            borderRadius: 1,
                        }}
                        inputProps={{
                            readOnly: true,
                        }}
                        startAdornment={
                            <InputAdornment position="start">
                                <  EmailOutlinedIcon />
                            </InputAdornment>
                        }

                    />
                </Box>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: 5,
                        marginBottom: 2,
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                >
                    <ColorButton
                        disabled={isLoading == true}
                        type="submit"
                        sx={{
                            borderRadius: 30,
                            width: 140,
                            mb: 2
                        }}
                        variant="contained"
                    >
                        SAVE
                    </ColorButton>
                </div>

            </form>
        )
    }
    return (
        <>
            <Box sx={pageStyle}>
                <>{renderLoginForm()}</>
            </Box>
        </>
    );
}

export default MyProfile;

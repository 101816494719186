import React, { useState , useEffect} from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import CircularProgress from '@mui/material/CircularProgress';
import Link from "@mui/material/Link";
import { useLocation } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import google from "./../../assets/images/socialMedia/google.svg";
import apple from "./../../assets/images/socialMedia/apple.svg";
import facebook from "./../../assets/images/socialMedia/facebook.svg";
import {
  createFirebaseAccountWithFacebook,
  createFirebaseAccountWithGoogle,
  createFirebaseAccountWithPassword,
  validateSignupForm,
  validateFacebookEmail
} from '../../Business/AuthenticationBusiness';
import { useNavigate } from 'react-router-dom';
import { createFirebaseAccountWithApple } from "../../Business/AuthenticationBusiness";
// import { getUserEmailVerify } from "../../Repository/Api";
import { createUser, getUserByEmailAuthId, verifyEmailFirebase } from "./../../Repository/Api";
import { updateEmailId } from "../../Repository/Authentication/Firebase"
import { getUserByAuthId, getUserByEmail, getUserById } from "../../Repository/Api";
import { getFirebaseAuthModule } from "../../Repository/Authentication/Firebase";
import { getFirebaseAuthJWTToken } from "../../Repository/Authentication/Firebase";
import { FacebookAuthProvider } from "firebase/auth";
import MuiAlert from '@mui/material/Alert';

function Register(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  let _location = useLocation();

  let _fromRoute = _location.state?.from?.pathname || "/cami-steps";

  const xlPage = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 80,
    width: "400px",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 15,
    marginBottom: 80,

  };
  const smPage = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 1,
  };
  const errorText = {
    color: "red",
    fontSize: 13,
    marginTop: 10,
    marginLeft: 2

  };
  const socialLogo = {
    padding: 16,
    paddingHorizontal: 20,
    height: 64,
    cursor: 'pointer'
  };
  const socialLogoContainer = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    my: 2,
  };
  const mainColor = "#981D97";
  const whiteColor = "#000";
  const inputFieldColor = "#ecedee";
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(whiteColor),
    backgroundColor: mainColor,
    "&:hover": {
      backgroundColor: "#bf3bbe",
    },
  }));

  const passwordConditionsStyle = {
    fontSize: 12,
    paddingTop: 10,
    marginLeft: 5,
    marginRight: 5
  }
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    showPassword: "false",
    showConfirmPassword: "false",
    confirmPassword: "",
    company: ""
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isMessage, setIsMessage] = useState(false)
  const [open, setOpen] = useState(false);
  const [showUi, setShowUi] = useState(false);
  const [emailOfUser, setEmailOfUser] = useState("");
  const [authId, setAuthId] = useState("");
  const [signInMethod, setSignInMethod] = useState("");
  const [firstName, setFirstName] = useState("")
  const [isEmailSubmit, setEmailSubmit] = useState(false);

  const handleClickAlert = () => {
    setOpen(true);
  };

  const handleCloseAlert = () => {
    setIsMessage(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleClickShowPassword = () => {
    setFormValues({
      ...formValues,
      showPassword: !formValues.showPassword,
    });
  };

  useEffect(()=>{
    reDirectFromUrl();
  },[]);

 function reDirectFromUrl(){
  const urlSearchParams = new URLSearchParams(window.location.search.toString().slice(1));
  if(urlSearchParams.has("email")){
    const emailOfReceiver = urlSearchParams.get('email').replace(/ /g, '');
    setFormValues({...formValues,"email":emailOfReceiver != ""?emailOfReceiver:""})
  }
  }
  const handleClickShowConfirmPassword = () => {
    setFormValues({
      ...formValues,
      showConfirmPassword: !formValues.showConfirmPassword,
    });
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const validation = validateSignupForm(formValues);
    setFormErrors(validation.errorList);
    if (validation.status === true) {
      setIsSubmit(true);
      createFirebaseAccountWithPassword(formValues)
        .then((success) => {
          setIsSubmit(false);
          setApiError("User account created successfully.");
          setFormValues(initialValues);
          e.stopPropagation();
          if (typeof props['type'] == 'undefined' && props['type'] != 'imported') {
            navigate(_fromRoute, { replace: true });
          }
        })
        .catch((error) => {
          setIsSubmit(false);
          setApiError(error);
        });
    }
  };

  const handleGoogleLogin = (e) => {
    createFirebaseAccountWithGoogle()
      .then((response) => {
        setApiError("User account created successfully.");
        if (typeof props['type'] == 'undefined' && props['type'] != 'imported') {
          navigate(_fromRoute, { replace: true });
        }
      })
      .catch((error) => {
        setApiError(error);
      });
  }

  const handleFacebookLogin = (e) => {
    createFirebaseAccountWithFacebook()
      .then((response) => {
        const credential = FacebookAuthProvider.credentialFromResult(response);
        const token = credential.accessToken;
        const user = response.user;
        const auth = getFirebaseAuthModule();
        let email = response.user.email;
        getUserByEmail(email).then((response) => {
          setApiError("Register Successful.");
          setApiError("User account created successfully.");
          e.stopPropagation();
          if (typeof props['type'] == 'undefined' && props['type'] != 'imported') {
            navigate(_fromRoute, { replace: true });
          }
        }).catch((error) => {
          return new Promise((resolve, reject) => {
            getFirebaseAuthJWTToken(auth)
              .then((IDTOKEN) => {
                verifyEmailFirebase(user.uid).then((res) => {
                })
                localStorage.setItem("logintype", "facebook");
                localStorage.setItem("FBUC", user);
                localStorage.setItem("JWTTOKEN", IDTOKEN);
                setAuthId(user.uid);
                setSignInMethod(credential.signInMethod);
                setEmailOfUser(user.email)
                setFirstName(user.displayName);
                if (user.email !== null || undefined || '') {
                  setShowUi(false)
                  const createUserData = {
                    authId: user.uid,
                    signInMethod: credential.signInMethod,
                    email: user.email,
                    firstName: user.displayName,
                    lastName: "",
                    userTypeId: 1,
                    userRoleId: 0,
                    createdBy: 0,
                    recordStatus: true,
                    registerStatus: true,

                  };
                  createUser(createUserData)

                    .then((response) => {
                      resolve(response);
                    })
                    .catch((error1) => {
                      reject(error1);
                    });
                } else {
                  setShowUi(true)

                }
              })
              .catch((error3) => {
                reject(error3);
              });
          })
        })
      }).catch((error) => {
        setApiError(error);
      })
  }



  const handleAppleLogin = (e) => {
    createFirebaseAccountWithApple()
      .then((response) => {
        setApiError("User Account created successfully.");
        setApiError("User account created successfully.");
        if (typeof props['type'] == 'undefined' && props['type'] != 'imported') {
          navigate(_fromRoute, { replace: true });
        }
      })
      .catch((error) => {

        setApiError(error);

      });
  }

  const handleSubmitEmail = (e) => {

    if (emailOfUser) {
      const createUserData = {
        "authId": authId,
        "signInMethod": signInMethod,
        "email": emailOfUser,
        "firstName": firstName,
        "lastName": "",
        "userTypeId": 1,
        "userRoleId": 0,
        "createdBy": 0,
        "recordStatus": true,
        "registerStatus": true,
      };
      const validation = validateFacebookEmail(createUserData);
      if (validation.status === true) {
        alert('inside createuser')
        createUser(createUserData)
          .then((response) => {
            updateEmailId(emailOfUser);
            setApiError("Register Successful");
  
          }).then(() => {
            if (typeof props['type'] == 'undefined' && props['type'] !== 'imported') {
              navigate(_fromRoute, { replace: true });
            }
          })
          .catch((error) => {
            setApiError(error);
          });
      }
    }

  }

  /**HTML Section */
  const renderSignUpForm = () => {
    if (matches) {
      return (
        <Box elevation={0} style={smPage}>
          <ThemeProvider theme={theme}>
            <Container
              component="main"
              maxWidth="xs"
              sx={{
                marginTop: 8,
                marginBottom: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CssBaseline />
              <Box >
                {showUi == false && renderSignUpFormContent()}
                {showUi == true && renderGetEmailFromUser()}
              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      );
    } else {
      return (
        <Paper elevation={2} style={xlPage}>
          <ThemeProvider theme={theme}>
            <Container
              component="main"
              maxWidth="xs"
              sx={{
                width: "100%",
                marginTop: 8,
                marginBottom: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CssBaseline />
              <Box>
                {/* <Typography
                  variant="h5"
                  sx={{
                    textAlign: "left",
                    alignItems: "flex-start",
                    color: "#981d97",
                  }}
                >
                  Sign Up
                </Typography> */}
                {showUi == false && renderSignUpFormContent()}
                {showUi == true && renderGetEmailFromContent()}
              </Box>

            </Container>
          </ThemeProvider>
        </Paper>
      );
    }
  };


  const renderGetEmailFromContent = () => {
    if (isSubmit === true) {
      return (
        <Box sx={{ textAlign: "center" }} >
          <CircularProgress sx={{
            marginTop: 3,
            marginBottom: 3,
            marginLeft: "auto",
            marginRight: "auto"
          }} />
        </Box>
      );
    } else {
      return (<>{renderGetEmailFromUser()}</>);
    }
  }

  const renderGetEmailFromUser = () => {
    if (showUi == true) {
      return (

        <>
          <Typography
            variant="h5"
            sx={{
              textAlign: "left",
              marginLeft: "auto",
              marginRight: "auto",
              color: "#981d97", mb: 2
            }}
          >
            Please enter your email ID to Create account!
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "auto",
              marginRight: "auto",
              mb: 2,
            }}
          >
            <InputBase
              size="small"
              id="user email"
              placeholder="Email"
              name="user email"
              autoComplete="email"
              autoFocus
              value={props.emailOfUser}
              onChange={(e) => { setEmailOfUser(e.target.value) }}
              sx={{
                mt: 2,
                mb: 2,
                backgroundColor: inputFieldColor,
                paddingBlock: 1,
                paddingInlineStart: 1,
                paddingInlineEnd: 1,
                borderRadius: 1,
              }}
            />
            <ColorButton
              onClick={handleSubmitEmail}
              sx={{
                borderRadius: 30,
                width: 140,
              }}
              variant="contained"
            >
              CONTINUE
            </ColorButton>
          </Box>
        </>
      )

    }
  }

  const renderSignUpFormContent = () => {
    if (isSubmit === true) {
      return (
        <Box>
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress
              sx={{
                marginTop: 1,
                marginBottom: 3,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", height: "60vh", maxWidth: "100%", }}>
            <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%' }}>
              Register successfully
            </Alert>
          </Box>
        </Box>
      );
    } else {
      return <>{SignUpFormContent()}</>;
    }
  };

  const SignUpFormContent = () => {
    return (
      <>
        <Typography
          variant="h5"
          sx={{
            textAlign: "left",
            alignItems: "flex-start",
            color: "#981d97",
          }}
        >
          Sign Up
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <InputBase
            size="small"
            id="firstName"
            placeholder="First name"
            name="firstName"
            autoComplete=" firstname"
            autoFocus
            value={formValues.firstName}
            onChange={handleChange}
            sx={{
              width: "100%",
              mt: 2,
              backgroundColor: inputFieldColor,
              paddingBlock: 1,
              paddingInlineStart: 1,
              paddingInlineEnd: 1,
              borderRadius: 1,
            }}
          />
          {formErrors.firstName && (
            <p style={errorText}>{formErrors.firstName}</p>
          )}
          <InputBase
            size="small"
            id="lastName"
            placeholder="Last name"
            name="lastName"
            autoComplete="last name"
            autoFocus
            value={formValues.lastName}
            onChange={handleChange}
            sx={{
              width: "100%",
              mt: 2,
              backgroundColor: inputFieldColor,
              paddingBlock: 1,
              paddingInlineStart: 1,
              paddingInlineEnd: 1,
              borderRadius: 1,
            }}
          />
          {formErrors.lastName && (
            <p style={errorText}>{formErrors.lastName}</p>
          )}
          <InputBase
            size="small"
            id="company"
            placeholder="Company(Optional)"
            name="company"
            autoComplete="company"
            autoFocus
            value={formValues.company}
            onChange={handleChange}
            sx={{
              width: "100%",
              mt: 2,
              backgroundColor: inputFieldColor,
              paddingBlock: 1,
              paddingInlineStart: 1,
              paddingInlineEnd: 1,
              borderRadius: 1,
            }}
          />
          <InputBase
            size="small"
            id="email"
            placeholder="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={formValues.email}
            onChange={handleChange}
            sx={{
              width: "100%",
              mt: 2,
              backgroundColor: inputFieldColor,
              paddingBlock: 1,
              paddingInlineStart: 1,
              paddingInlineEnd: 1,
              borderRadius: 1,
            }}
          />

          {formErrors.email && <p style={errorText}>{formErrors.email}</p>}
          {formErrors.emailValid && <p style ={errorText}>Please enter the valid email address</p>}

          <InputBase
            size="small"
            name="password"
            placeholder="Password"
            type={formValues.showPassword ? 'password' : 'text'}
            id="password"
            autoComplete="current-password"
            value={formValues.password}
            onChange={handleChange}
            sx={{
              width: "100%",
              backgroundColor: inputFieldColor,
              paddingBlock: 1,
              paddingInlineStart: 1,
              paddingInlineEnd: 1,
              borderRadius: 1,
              mt: 2,
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onMouseUp={handleClickShowPassword}
                  onMouseDown={handleClickShowPassword}
                  onTouchStart={handleClickShowPassword}
                  onTouchEnd={handleClickShowPassword}
                  onTouchCancel={handleClickShowPassword}
                  edge="end"
                >
                  {formValues.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>}
          />

          {formErrors.passwordValid && (<p style={errorText}>{formErrors.passwordValid}</p>)}

          {!formErrors.passwordValid && (<p style={passwordConditionsStyle}>Use 8 or more characters with a mix of letters, numbers & symbols</p>)}

          <InputBase
            size="small"
            name="confirmPassword"
            placeholder="Confirm Password"
            type={formValues.showConfirmPassword ? 'password' : 'text'}
            id="confirm_Password"
            // autoComplete="current-password"
            value={formValues.confirmPassword}
            onChange={handleChange}
            sx={{
              width: "100%",
              backgroundColor: inputFieldColor,
              paddingBlock: 1,
              paddingInlineStart: 1,
              paddingInlineEnd: 1,
              borderRadius: 1,
              mt: 2,
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onMouseUp={handleClickShowConfirmPassword}
                  onMouseDown={handleClickShowConfirmPassword}
                  onTouchStart={handleClickShowConfirmPassword}
                  onTouchEnd={handleClickShowConfirmPassword}
                  onTouchCancel={handleClickShowConfirmPassword}
                  edge="end"
                >
                  {formValues.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>}
          />


          {formErrors.password && (
            <p style={errorText}>{formErrors.password}</p>
          )}
          {apiError && <p style={errorText}>{apiError}</p>}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 3,
              marginBottom: 2,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <ColorButton
              type="submit"
              sx={{
                borderRadius: 30,
                width: 230,
                margin: 3,
              }}
              variant="contained"
            >
              CREATE ACCOUNT
            </ColorButton>
          </div>
          <Box container style={socialLogoContainer}>
            <Link href="#">
              {" "}
              <img
                src={google}
                style={socialLogo}
                onClick={handleGoogleLogin}
              ></img>{" "}
            </Link>
            <Link href="#">
              {" "}
              <img src={apple} style={socialLogo} onClick={handleAppleLogin}></img>{" "}
            </Link>
            <Link href="#">
              {" "}
              <img
                src={facebook}
                style={socialLogo}
                onClick={handleFacebookLogin}
              ></img>{" "}
            </Link>
          </Box>
        </Box>
      </>
    );
  };

  return <>{renderSignUpForm()}</>;
}
export default Register;

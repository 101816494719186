import { initializeApp, getApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { Observable } from "rxjs";
import { OAuthProvider, updateEmail } from "firebase/auth";
import { getStorage } from "firebase/storage";

export let getCurrentSignedInUser = () => {
  return new Observable((observer) => {
    const app = initializeApp(firebaseConfig());
    const auth = getAuth(app);
    const storage = getStorage(app);
    onAuthStateChanged(auth, (user) => {
      if (user || null || undefined || '') {
        observer.next({ state: "logged in", user: user });
      } else {
        observer.next({ state: "logged out" });
      }
    });
  });
};

//staging
export let firebaseConfig = () => {
  return {
    apiKey: "AIzaSyBYxNjx45ZR2Zz1drmrnl59yoJxQdKw2NE",
    authDomain: "camicode-b4c91.firebaseapp.com",
    projectId: "camicode-b4c91",
    storageBucket: "camicode-b4c91.appspot.com",
    messagingSenderId: "597783244030",
    appId: "1:597783244030:web:22c717430e19af3c4889af",
    measurementId: "G-LXLXHTFKYW",
  };
};

//production
export let firebaseConfigProduction = () => {
  return {
    apiKey: "AIzaSyDia2uFjt-QrzySzHewimNf2moRA27THiA",
    authDomain: "camicodeprod.firebaseapp.com",
    projectId: "camicodeprod",
    storageBucket: "camicodeprod.appspot.com",
    messagingSenderId: "109271657763",
    appId: "1:109271657763:web:fbd21c48513010ae50f096",
    measurementId: "G-LPX9SP6845"
  };
 
};


export let storageModule = () => {
  const app = getApp();
  if (!app) {
    const app = initializeApp(firebaseConfig());
    const storage = getStorage(app);
    return storage;
  }
  else {
    const storage = getStorage(app);
    return storage;
  }
}


export let CreatePasswordBasedAccount = (auth, email, password) => {
  return new Promise((resolve, reject) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCrendetial) => resolve(userCrendetial))
      .catch((error) => {
        if (error.code === "auth/email-already-in-use") {
          reject("Email already exist");
        }
      });
  });
};

export let loginPasswordBasedAccount = (auth, email, password) => {
  return new Promise((resolve, reject) => {
    setPersistence(auth, browserLocalPersistence).then(() => {
      signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
        resolve(userCredential)
      }).catch((error) => {
        if (error.code === "auth/user-not-found") {
          reject("User not found.  Check your registered email ID");
        }
        if (error.code === "auth/wrong-password") {
          reject("Password incorrect");
        }
      });
    })
  });
};

export let getFirebaseAuthJWTToken = async (auth) => {
  return auth.currentUser.getIdToken(true);
};

export let getGoogleProvider = () => {
  const provider = new GoogleAuthProvider();
  provider.addScope("profile email");
  return provider;
};

export let getFacebookProvider = () => {
  const provider = new FacebookAuthProvider();
  provider.addScope("public_profile");
  return provider;
};

export let signinWithApple = () => {
  const provider = new OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');
  return provider;

}

export let getFirebaseAuthModule = () => {
  const app = initializeApp(firebaseConfig());
  const auth = getAuth(app);
  return auth;
};

export let doSignOut = (auth) => {
  return auth.signOut();
}
export let updateEmailId = (email) => {
  const auth = getAuth();
  auth.currentUser.getIdToken(true)
    .then(idToken => {
      return new Promise((resolve, reject) => {
        updateEmail(auth.currentUser, email).then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        });
      })
    })
    .catch(error => {
      if (error.code === "auth/user-token-expired") {
      }
    })
}
export let sendFirebasePasswordResetEmail = (auth, email) => {
  return new Promise((resolve, reject) => {
    var actionCodeSettings = {
      url: window.location.origin + "/login"
    };
    sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then((success) => {
        resolve(success);
      })
      .catch((error) => {
        reject(error);
      });
  });

}

export let getFacebookEmail = (token) => {
  return new Promise((resolve, reject) => {
    var graphUrl = 'https://graph.facebook.com/me?fields=id,name,email&scope=email&access_token=' + token;
    fetch(graphUrl, {
      method: "GET",
      mode: "cors",
      cache: "no-cache"
    })
      .then((response) => {
        resolve(response.json());
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          reject("User not found");
        }

      });
  });

}

export let getUserLoginStatus = () => {
  const auth = getFirebaseAuthModule();
}


const ModulesExported = {
  getFirebaseAuthModule,
  firebaseConfig,
  firebaseConfigProduction,
  getFirebaseAuthJWTToken,
  CreatePasswordBasedAccount,
  loginPasswordBasedAccount,
  getGoogleProvider,
  getFacebookProvider,
  getCurrentSignedInUser,
  doSignOut,
  sendFirebasePasswordResetEmail,
  getFacebookEmail,
  signinWithApple,
  storageModule,
  getUserLoginStatus,
  updateEmailId

};
export default ModulesExported;

import React, { useEffect, useState } from 'react'
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from "@mui/material/Button";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Alert, Avatar, CircularProgress, Divider, InputBase, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { useRef } from "react";

import { styled, alpha } from "@mui/material/styles";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getFirebaseAuthModule } from '../../Repository/Authentication/Firebase';
import { getCamiCode, getCamiCodeImage, getUserByAuthId, getUserByEmail ,getToken,sendPushNotification} from '../../Repository/Api';
import { Link, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import camiCodeImg from '../../assets/images/camiCode/camiCodeImg'
import { useLocation } from 'react-router-dom';
import { downloadFile } from '../../Business/MediaBusiness';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSate } from 'react';
import { saveAs } from 'file-saver';
import FileSaver from 'file-saver';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import { Logout, PersonAdd, Settings } from '@mui/icons-material';
import { baseURL } from '../../Repository/Api';
import ShareCreatedCamiCode from '../Components/ShareCreatedCamiCode';
// import downloadImg from './../../assets/images/buttonIcons/download.svg'
import { sendToUser } from '../../Repository/Api';

function CamiCard(props) {
    const [data, setData] = useState([]);
    const [id, setId] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [imageResponse, setImageResponse] = useState("");
    const [openModal, setOpenModal] = useState(false);
    // const [toEmail, setToEmail] = useState("");
    const [currentUserId, setCurrentUserId] = useState("");
    const [dataToShare, setDataToShare] = useState("");
    const [message, setMessage] = useState(false);
    // const [email, setEmail] = useState("");
    const mainColor = "#981D97";
    const whiteColor = "#000";
    const inputFieldColor = "#ecedee";
    const [sentToInfo, setSentToInfo] = useState("");
    const [psId, setPsId] = useState("");
    const [propCreatedSubject, setPropCreatedSubject] = useState("");
    const [propSubByEmail, setPropSubByEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [detByEmail, setDetByEmail] = useState("");
    const [emailFromUrl, setEmailFromUrl] = useState("");
    const [fromSubject, setFromSubject] = useState("");
    const [pass, setPass] = useState("");
    const [camiType,setCamiType] = useState('');
    const navigate = useNavigate();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const fromNameRef = useRef();
    const toNameRef = useRef();
    const emailRef = useRef();
    const subjectRef = useRef();
    const emailValue = '';
    let _location = useLocation();
    const currentURL = window.location.href;
    const currentURLPath = window.location.pathname;
    let _fromRoute = _location.state?.from?.pathname


    const CustomButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText("#000"),
        backgroundColor: mainColor,
        "&:hover": {
            backgroundColor: "#bf3bbe",
        },
    }));

    const containerStyle = {
        backgroundColor: "#fff",
        width: 110,
        height: 140,
        elevation: 2,
        margin: 0.5
    }

    const descriptionStyle = {
        fontSize: 10,
        textDecoration: "none",
        fontWeight: "bold",
        minHeight: "20px"
    }

    const titleStyle = {
        fontSize: 10,
        fontWeight: "bold"
    }

    const imgStyle = {
        width: 150, paddingLeft: 30, paddingRight: 30, resize: "cover",
        paddingBottom: 10,
    }

    const logoImage = {
        maxHeight: "400",
        maxWidth: "400",
        paddingBottom: "3px",
    };

    const buttonStyle = {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#9c27b0",
        color: "white",
        borderRadius: "25px",
        // height:"100%",
        fontSize: "10px",
        height: "30px",
        textTransform: "capitalize",
    };
    const printCodeStyle = {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#8ccccc",
        borderRadius: "30px",
        fontSize: "10px",
        height: "30px",
        textTransform: "capitalize"
    };
    const sentToStyle = {
        fontSize: 16,
        textAlign: "start",
        padding: 0,
        fontWeight: "bold",
        width: '150px',
        minHeight: "20px",
    }

    const hide = {
        display: "none"
    }

    // get camitag ID from url
    useEffect(() => {
        const camiCodeId = getCamitagFromUrl();
        setId(camiCodeId )
        const psId = getPaymentStatusFromURL();
        setPsId(psId);
        getReceiverEmailFromUrl();
    }, []);

    function getReceiverEmailFromUrl() {
        const urlSearchParams = new URLSearchParams(window.location.search.toString().slice(1));
        if (urlSearchParams.has('e')) {
            const emailOfReceiver = urlSearchParams.get('e').replace(/ /g, '');
            setEmailFromUrl(emailOfReceiver)
        }
    }

    useEffect(() => {
        if (id && pass) {
            requestData();

        }
    }, [id, pass]);

    let getPaymentStatusFromURL = () => {
        try {
            const urlParams = new URLSearchParams(window.location.search);
            const paymentStatus = urlParams.get('ps') || "";
            return paymentStatus;
        } catch (error) {
            return "";
        }
    }

    let getCamitagFromUrl = () => {
        return getCamitag(window.location);
    }

    let getCamitag = (url) => {
        try {
            const urlparams = new URLSearchParams(url.search);
            const _camitagid = urlparams.get('c') || "";
            return _camitagid;
        } catch (error) {
            return "";
        }
    }

    // get camitag ID from url
    const CustomIconButton = styled(IconButton)(({ theme }) => ({
        color: theme.palette.getContrastText(whiteColor),
        padding: '5px',
        backgroundColor: mainColor,
        "&:hover": {
            backgroundColor: "#bf3bbe",
        },
    }));

    //get corresponding camicode from API - getCamiCodeByID
    function requestData() {
        setIsLoading(true);
        getCamiCode(id, pass).then((data) => {
            setData(data);
            setCamiType(data.camiTypeId)
            setIsLoading(false);
        }).catch((error) => {
        });
    }

    
    function handleCloseClick() {
        navigate("/received-camicode")
    }

    const downloadVideoURL =(data['videoId']) ? ('https://cdn.api.video/vod/' + data['videoId'] + '/mp4/source.mp4' ):( data['fileUrl'])
    const camiTagImage = baseURL + '/api/CamiCode/GenerateQrCode?camiCode=' + id;
    const downLoadImage = baseURL + '/api/CamiCode/DownloadQrCode?camiCode=' + id;
    const watchUrl = (emailFromUrl) ? ('/watch?c=' + data.camiCode + "&p=" + pass.toString().replace(/ /g, '') + '&redir=' + encodeURIComponent('/camicard') + '&e=' + emailFromUrl) : ('/watch?c=' + data.camiCode + "&p=" + pass.toString().replace(/ /g, '') + '&redir=' + encodeURIComponent('/camicard'))

    const handleClickOpenModal = () => {
        setOpenModal(true);
    };


    function handleCancelModal() {
        setOpenModal(false);
    };


    function handleCloseModal(e) {
        setOpenModal(false);
        handleSend(e);
        clearStorage();
    };

    const handleSend = (e) => {
        // e.preventDefault();
        networkCall()
    }


    const fetchData = async (user) => {
        try {
            const getUserDetails = await getUserByAuthId(user)
            setCurrentUserId(getUserDetails?.id);
            setUserName(getUserDetails.firstName);
        } catch (error) {
        }
    }

    useEffect(() => {
        const auth = getFirebaseAuthModule();
        auth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken().then((newToken) => {
                    localStorage.setItem("JWTTOKEN", newToken);
                    fetchData(user.uid);
                });
            } else if (user = undefined || null) {
                fetchData(user.uid);
            }
        }
        );
        getPasswordFromQuery();
    }, []);

    function getPasswordFromQuery() {
        const urlSearchParams = new URLSearchParams(window.location.search.toString().slice(1));
        if (urlSearchParams.has('p')) {
            const _pass = urlSearchParams.get('p').replace(/ /g, '');
            setPass(_pass);
        }
    }


    function getCreatedDetails() {
        if (currentUserId && id && pass) {
            const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
            const _baseUrl = baseURL + "/api/CamiCode/GetCreatedCamiCodeDet"
            const params = new URLSearchParams({
                userId: currentUserId,
                camiCode: id,
                uniqueId: pass
            });
            fetch(_baseUrl + '?' + params.toString(), {
                method: "GET",
                mode: "cors",
                headers: {
                    'content-Type': 'application/json',
                    'Authorization': token
                },
            }).then((res) => res.json()).then((result) => {
                setSentToInfo(result)
                setPropCreatedSubject(result?.subject)
            }).catch((error) => {
            });
        }
    }

    useEffect(() => {
        getCreatedDetails();
    }, [currentUserId, id, pass])

    function clearStorage() {
        localStorage.removeItem('CURRENTNAME');
        localStorage.removeItem('TOEMAIL');
        localStorage.removeItem('TONAME');
        localStorage.removeItem('TOSUBJECT');
    }

    function getCreatedDetailsByEmail() {
        if (data.vEmail && id && pass) {
            const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
            const _baseUrl = baseURL + "/api/CamiCode/GetCreatedCamiCodeDetByEmail?email=" + data.vEmail.toString().replace(/ /g, '') + "&camiCode=" + id.toString().replace(/ /g, '') + "&uniqueId=" + pass.toString().replace(/ /g, '');
            fetch(_baseUrl, {
                method: "GET",
                mode: "cors",
                headers: {
                    'content-Type': 'application/json',
                    'Authorization': token
                },
            }).then((res) => res.json()).then((result) => {
                setDetByEmail(result);
                setPropSubByEmail(result.subject);
                setFromSubject(result);

            }).catch((error) => {
            });
        }
    }
    useEffect(() => {
        getCreatedDetailsByEmail()
    }, [data.vEmail])

    function clearStorage() {
        localStorage.removeItem('CURRENTNAME');
        localStorage.removeItem('TOEMAIL');
        localStorage.removeItem('TONAME');
        localStorage.removeItem('TOSUBJECT');
    }

    function networkCall() {
        const formName = localStorage.getItem('CURRENTNAME');
        const formToEmail = localStorage.getItem('TOEMAIL');
        const formToName = localStorage.getItem('TONAME');
        const formSubject = localStorage.getItem('TOSUBJECT');
        clearStorage();
        if (id && formName && formToName && formToEmail) {
            const _payload = {
                "fromName": formName,
                "toName": formToName,
                "email": formToEmail,
                "subject": formSubject,
                "camiCodeId": id,
                "userId": 0,
                "loggedUser": (currentUserId) ? (currentUserId) : (0)
            };
            // localStorage.clear();
            const tokencrendital= {
                "senderId":"0",
                "receiverId":formToEmail,
                "camiCode":id
            }
            setDataToShare(_payload);
            sendToUser(_payload).then((res) => {
                if (res) {
                    setMessage(true)
                    getToken(tokencrendital).then((res)=>{
                        sendPushNotification(res)
                    });
                } else {
                }
            }).catch((error) => {
                // display error message.
            });
        }
    }

    function handleWatch() {
        window.open(watchUrl, "_blank")
    }

    async function handleDownload() {
        let blob = await fetch(downLoadImage).then((v) => v.blob())
        FileSaver.saveAs(blob, "CAMI.jpeg");
    }

    function DownloadDropDown() {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };
        return (
            <React.Fragment>
                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                    <Tooltip title="Select download option">
                        <CustomIconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <DownloadIcon />
                        </CustomIconButton>
                    </Tooltip>
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={handleDownload}>
                        <a style={{ textDecoration: "none" }} target="_blank">CAMIcode</a>
                    </MenuItem>
                    <MenuItem onClick={()=>downloadFile(downloadVideoURL)}>
                        Video / PDF or Image
                    </MenuItem>
                </Menu>
            </React.Fragment>
        );
    }


    const dialog = () => {
        return (
            <Dialog open={openModal} onClose={handleClickOpenModal}>
                <DialogTitle sx={{ paddingBottom: 0 }}>Share CAMIcode </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>
                              Please enter the email ID
                            </DialogContentText> */}
                    <ShareCreatedCamiCode
                        fromCurrentUserName={userName}
                        shareSubject={fromSubject?.subject}
                        currentSubject={propCreatedSubject}
                        handleCancelModal={handleCancelModal}
                        handleCloseModal={handleCloseModal} />
                </DialogContent>
                <DialogActions sx={{ alignSelf: "center" }}>
                </DialogActions>
            </Dialog>
        )
    }

    function RenderAllContent() {
        const [open, setOpen] = useState(false);

        const handleClickAlert = () => {
            setOpen(true);
        };



        return (<>{renderCamiCard()}</>);

    }
    const renderCamiCard = () => {
        if (id) {
            return (<Box sx={{ margin: '0 auto' }}>
                <Box sx={{ textAlign: "center" }} >
                    <Typography sx={{ color: mainColor }} gutterBottom variant="h5" component="div">
                        {(!psId) ? ('Created CAMIcode') : ('Your CAMIcode')}
                    </Typography>
                </Box>

                <Paper sx={{ padding: 2, width: 260 }}>
                <div style={((camiType== 3) == false  )? {} : hide}>
                    <Box sx={{ textAlign: "right", width: "100%" }}>
                        <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title={("Your video will remain live for " + sentToInfo.remainingDays || detByEmail.remainingDays + " more days, download to keep forever")}>
                            <Typography sx={{ textAlign: "right", width: "100%" }}> {sentToInfo.remainingDays || detByEmail.remainingDays}/60 days</Typography>
                        </Tooltip>
                    </Box>
                    </div>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", }}>
                        <Button sx={{ right: -26, top: -12, width: 25, height: 25, elevation: 2 }}
                            onClose={handleCloseClick}
                            disabled
                        // endIcon={<CancelIcon />}
                        ></Button>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <img src={camiTagImage} style={{ height: 150 }}></img>
                    </Box>
                    <Box sx={{ marginLeft: 1, paddingTop: 2, paddingBottom: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", }}>
                        <Typography variant="h6" sx={sentToStyle}>  {(sentToInfo?.sentTo) ? (sentToInfo?.sentTo) : ("") || (detByEmail?.sentTo) ? (detByEmail?.sentTo) : ("")}</Typography>
                    </Box>
                    <Box sx={{ marginLeft: 1, width: "100%", marginBottom: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", }}>
                        <Typography variant="p" fontSize={10} sx={descriptionStyle}>{(sentToInfo?.subject) ? (sentToInfo?.subject) : ("") || (detByEmail?.subject) ? (detByEmail?.subject) : ("")}</Typography>
                    </Box>
                    <Box sx={{ paddingTop: 1, paddingBottom: 1, paddingLeft: 1, paddingRight: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                        {DownloadDropDown()}
                        <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title="Share CAMIcode">
                            <CustomIconButton
                                variant="contained"
                                // sx={printCodeStyle}
                                onClick={handleClickOpenModal}
                            >
                                <ShareIcon />
                            </CustomIconButton>
                        </Tooltip>
                        <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title="Play Video or Preview Image/PDF">
                            <CustomIconButton
                                onClick={handleWatch}
                                aria-label="Play"
                                sx={{ borderRadius: 25, }} >
                                <PlayArrowIcon />
                            </CustomIconButton>
                        </Tooltip>
                        {dialog()}
                    </Box>
                </Paper>
            </Box>
            )
        } else {
            return null;
        }
    }

    const handleCloseAlert = () => {
        setMessage(false);
    };

    if (isLoading) {
        return <Box sx={{ width: '100%', textAlign: 'center', paddingTop: '150px' }} ><CircularProgress sx={{ position: 'relative', width: '100%', margin: '0 auto' }} /></Box>
    } else if (message) {
        return (
            <>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", height: "80vh", minWidth: "50vw", textAlign: 'center' }}>
                    <Alert onClose={handleCloseAlert} severity="success" >
                        CAMIcode Shared!
                    </Alert>
                </Box>
            </>
        );
    } else {
        return (
        <>
            <Box sx={{ color: "#b157b0", marginLeft: 2, marginBottom: 3, marginTop: 5, fontSize: 15 }}>
                {/* <Typography variant="h6">
                    Created CAMIcodes
                </Typography> */}
            </Box>
            {matches ? (<Box sx={{ width: "100vw", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <RenderAllContent></RenderAllContent>
            </Box>) : (
                <Box sx={{ marginBottom: 2, display: "flex", flexDirection: "column", alignItems: "flex-start", paddingLeft: 2 }}>
                    <RenderAllContent></RenderAllContent>

                </Box>
            )}
        </>
        )
    }

}

export default CamiCard;

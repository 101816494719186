import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from "@mui/material/styles";
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import { purple } from '@mui/material/colors';
import { Typography } from "@mui/material";
import { signOut } from '../../Business/AuthenticationBusiness';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { getUserByAuthId, getUserByEmail } from '../../Repository/Api';
import { getFirebaseAuthModule } from '../../Repository/Authentication/Firebase';
import CircularProgress from '@mui/material/CircularProgress';


export default function MenuLink(props) {

    const [dark, setDark] = useState(false);
    const [login, setLogin] = useState(null);
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(true);
    const [openMyAccountDrawer, setOpenMyAccountDrawer] = useState(true);
    const [state, setState] = useState(false);
    const [apiData, setApiData] = useState('');
    const [freeCamiExist, setFreeCamiExist] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [camiforeverExist, setCamiforeverExist] = useState(false);
   
    useEffect(() => { setDark(props.dark); }, [props.dark]);
    useEffect(() => { setLogin(props.login); }, [props.login]);
    useEffect(() => { setState(props.state); }, [props.state]);
    const theme = useTheme();

    const mainColor = "#981D97";
    const whiteColor = "#000";
    const inputFieldColor = "#ecedee";
    const accent = purple[500];
    const secondary = "white";
    const defaultColor = "#981D97"
    const subMenuTextStyle = {
        fontSize: 14
    }
    const navigate = useNavigate();
    const StyledMenu = styled((props) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': { padding: '4px 0', },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    }));

    const dropDownButtonStyle = {
        textTransform: "capitalize",
        color: theme.palette.getContrastText(whiteColor),
        backgroundColor: mainColor,
        "&:hover": { backgroundColor: mainColor, },
    }

    function handleCloseNavMenu() {
        setAnchorElNav(null);
    }
    function toggleDrawer(openDrawer) {
        return function (event) {
            if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
                return;
            }
            setState(openDrawer);
            setOpenDrawer(false);
            setOpenMyAccountDrawer(false);
        }
    }
    // navigate login
    function signOutAction() {
        const status = signOut();
        navigate('/login');
    }


    useEffect(() => {
        const auth = getFirebaseAuthModule();
        auth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken().then((newToken) => {
                    localStorage.setItem("JWTTOKEN", newToken);
                    getFreeCami(user.uid)
                });
            } else {
                return ((error) => { })
            }
        }
        );
    }, []);
    const getFreeCami = async (user) => {
        setIsLoading(true);
        const res = await getUserByAuthId(user)
            .then(response => {
                setApiData(response.userPhoto);
                setIsLoading(false);
                if (response['freeCamiCodes']) {
                    if (response['freeCamiCodes'] > 0) {
                        setFreeCamiExist(true)
                    } else {
                        setFreeCamiExist(false)
                    }
                }
                if(response['foreverCamiCodes']>0){
                    setCamiforeverExist(true)
                 }else{
                     setCamiforeverExist(false)
                 }
            }).catch(error => {})
    }

    function circularIndeterminate() {
        return (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box>
        );
    }


    function RenderDropdownButtonCreateCamicodeHeader() {
        const [anchorEl, setAnchorEl] = useState(null);
        const openRecorderMenu = Boolean(anchorEl);
        function handleClickSUbMenu(event) {
            setAnchorEl(event.currentTarget);
        };
        function handleClose() {
            setAnchorEl(null);
        };
        return (
            <div style={{ paddingVertical: 6, paddingHorizontal: 8, marginTop: 16 }}>
                <Button
                    id="demo-recorder-button"
                    aria-controls={openRecorderMenu ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openRecorderMenu ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClickSUbMenu}
                    endIcon={<KeyboardArrowDownIcon />}
                    sx={dropDownButtonStyle}
                >
                    CAMIcodes
                </Button>
                <StyledMenu
                    id="demo-recorder-menu"
                    MenuListProps={{ 'aria-labelledby': 'demo-recorder-button', }}
                    anchorEl={anchorEl}
                    getcontentanchorel={null}
                    open={openRecorderMenu}
                    onClose={handleClose}>
                    <Link to="cami-steps" style={{ textDecoration: "none", color: defaultColor }} >
                        <MenuItem sx={subMenuTextStyle} onClick={handleClose} disableRipple>Create CAMIcode</MenuItem>
                    </Link>
                    <Link to="scan-cami" style={{ textDecoration: "none", color: defaultColor }} >
                        <MenuItem sx={subMenuTextStyle} onClick={handleClose} disableRipple> Scan CAMIcode</MenuItem>
                    </Link>
                </StyledMenu>
            </div>
        )
    }

    function RenderDropdownButtonMyCamicodeHeader() {
        const [anchorEl, setAnchorEl] = useState(null);
        const openSubMenu = Boolean(anchorEl);
        function handleClickSUbMenu(event) {
            setAnchorEl(event.currentTarget);
        };
        function handleClose() {
            setAnchorEl(null);
        };
        return (
            <div style={{ paddingVertical: 6, paddingHorizontal: 8, marginTop: 16 }}>
                <Button
                    id="demo-customized-button"
                    aria-controls={openSubMenu ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openSubMenu ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClickSUbMenu}
                    endIcon={<KeyboardArrowDownIcon />}
                    sx={dropDownButtonStyle}
                >
                    My CAMIcodes
                </Button>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{ 'aria-labelledby': 'demo-customized-button', }}
                    anchorEl={anchorEl}
                    getcontentanchorel={null}
                    open={openSubMenu}
                    onClose={handleClose}>
                    <Link to="created-camicode" style={{ textDecoration: "none", color: defaultColor }} >
                        <MenuItem sx={subMenuTextStyle} onClick={handleClose} disableRipple>
                            Created CAMIcodes
                        </MenuItem>
                    </Link>
                    <Link to="received-camicode" style={{ textDecoration: "none", color: defaultColor }} >
                        <MenuItem sx={subMenuTextStyle} onClick={handleClose} disableRipple>
                            Received CAMIcodes
                        </MenuItem>
                    </Link>

                </StyledMenu>
            </div>
        )
    }
    function RenderDropdownButtonMyAccountHeader() {
        const [anchorElAcc, setAnchorElAcc] = useState(null);
        const openSubMenuMyAccountHeader = Boolean(anchorElAcc);
        function handleClickSUbMenuMyAcc(event) {
            setAnchorElAcc(event.currentTarget);
        }
        function handleCloseMyAcc() {
            setAnchorElAcc(null);
        }
        return (
            <div style={{ paddingVertical: 6, paddingHorizontal: 8, marginTop: 16 }}>
                <Button
                    id="customized-button"
                    aria-controls={openSubMenuMyAccountHeader ? 'customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openSubMenuMyAccountHeader ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClickSUbMenuMyAcc}
                    endIcon={<KeyboardArrowDownIcon />}
                    sx={dropDownButtonStyle}
                >
                    My Account
                </Button>
                <StyledMenu
                    id="customized-menu"
                    MenuListProps={{ 'aria-labelledby': 'customized-button', }}
                    anchorEl={anchorElAcc}
                    getcontentanchorel={null}
                    open={openSubMenuMyAccountHeader}
                    onClose={handleCloseMyAcc}
                >
                    <Link to="my-payment-methods" style={{ textDecoration: "none", color: defaultColor }} >
                        <MenuItem sx={subMenuTextStyle} onClick={handleCloseMyAcc} disableRipple>My Payment Method  </MenuItem>
                    </Link>
                    <Link to="my-transaction" style={{ textDecoration: "none", color: defaultColor }} >
                        <MenuItem sx={subMenuTextStyle} onClick={handleCloseMyAcc} disableRipple>My Transactions </MenuItem>
                    </Link>
                    {freeCamiExist == true && <Link to="free-cami" style={{ textDecoration: "none", color: defaultColor }} >
                        <MenuItem sx={subMenuTextStyle} onClick={handleCloseMyAcc} disableRipple>Free CAMIcodes </MenuItem>
                    </Link>}
                    {camiforeverExist == true && <Link to="forever-cami" style={{ textDecoration: "none", color: defaultColor }} >
                        <MenuItem sx={subMenuTextStyle} onClick={handleCloseMyAcc} disableRipple>CAMIforever</MenuItem>
                    </Link>}
                </StyledMenu>
            </div>
        )
    }

    
    function RenderProfile() {
        const [anchorEl, setAnchorEl] = useState(null);
        const isMenuOpen = Boolean(anchorEl);
        const handleProfileMenuOpen = (event) => {
            const value = event.currentTarget;
            setAnchorEl(value);

        };
        const handleMenuClose = () => {
            setAnchorEl(null);
        };


        return (
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end"}}>
                <IconButton
                    size="large"
                    edge="end"
                    aria-label="image_of_current_user"
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                >
                      {isLoading == true && circularIndeterminate()}
                      {isLoading == false &&  <Avatar src={apiData} alt="Profile_pic" />}
                </IconButton>            
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                >
                    <Link to="my-profile" style={{ textDecoration: "none", color: defaultColor }} >
                        <MenuItem sx={subMenuTextStyle} onClick={handleMenuClose} disableRipple> My Profile</MenuItem>
                    </Link>
                    <Button onClick={signOutAction} style={{ color: defaultColor, textTransform: "capitalize", paddingLeft: "15px" }}  >
                        <Typography> Logout </Typography>
                    </Button>
                </Menu>
            </Box>
        );
    }

    function getMenuPaths() {
        const btnDarkColor = { textTransform: "capitalize !important", my: 2, color: "white", display: "block", };
        const btnLightColor = { textTransform: "capitalize !important", my: 2, color: "#981D97", display: "block" };
        const btnColor = (dark) ? btnDarkColor : btnLightColor;
        const linkDarkColor = { textDecoration: "none", color: "white", };
        const linkLightColor = { textDecoration: "none", color: "#8d32a8" };
        const linkColor = (dark) ? linkDarkColor : linkLightColor;
        if (login === true) {
            return (
                <>
                    <RenderDropdownButtonCreateCamicodeHeader />
                    <RenderDropdownButtonMyCamicodeHeader />
                    <RenderDropdownButtonMyAccountHeader />
                    <Button sx={btnColor}>
                        <a href="https://camicode.com/pages/about-cami"
                            style={{
                                backgroundColor: "#981D97",
                                color: "#fff",
                                textDecoration: "none"

                            }}
                        >
                            About CAMI
                        </a>
                    </Button>
                 <RenderProfile/>
                </>
            )
        } else if (login === false) {
            return (
                <>   <RenderDropdownButtonCreateCamicodeHeader />
                    <Button onClick={handleCloseNavMenu} sx={btnColor} >
                        <Link style={linkColor} to="/login" >
                            <Typography >
                                Login
                            </Typography>
                        </Link>
                    </Button>
                    <Button onClick={handleCloseNavMenu} sx={btnColor} >
                        <Link style={linkColor} to="/register" >
                            <Typography  >
                                Register
                            </Typography>
                        </Link>
                    </Button>
                    <Button onClick={handleCloseNavMenu} sx={btnColor} >
                        <a href="https://camicode.com/pages/about-cami"
                            style={{
                                backgroundColor: "#981D97",
                                color: "#fff",
                                textDecoration: "none"

                            }}
                        >
                            About CAMI
                        </a>
                    </Button>

                </>
            )
        }
    }

    return (
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {getMenuPaths()}
        </Box>
    );
}
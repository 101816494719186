import { Box, Button, Card, CircularProgress, Dialog, DialogTitle, Fab, InputBase, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useRef, useState } from "react";
import { getCamitagFromUrl, getRecordingTimeFromLS, getRecordingTimeFromUrl, getSupportedMedia, getVideoConstraints, identifyFacingModeSupport, mapCamitagAndVideo, requestMedia, uploadRecordedVideoBusiness } from "../../Business/MediaBusiness";
import IconButton from '@mui/material/IconButton';
import { CloudUpload, LocalActivity, RadioButtonChecked, StopCircle } from '@mui/icons-material';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from 'react-router-dom';
import RecordTimer from './RecordTimer';
import { getFirebaseAuthModule } from '../../Repository/Authentication/Firebase';
import { createUser, getCamiCode, getCamitagFromId, getCardDetails, getUserByAuthId, UpdateCAMIApi } from '../../Repository/Api';
import { styled } from "@mui/material/styles";
import CheckoutForm from './CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import '../Components/Payment.css';
import PropTypes from 'prop-types';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { baseURL } from '../../Repository/Api';
import adapter from 'webrtc-adapter';
import FlipCameraIosOutlinedIcon from '@mui/icons-material/FlipCameraIosOutlined';
import "./MediaRecorder.css";
import { getBearerToken, getVideoStatus, generateUploadToken } from "../../Repository/ApiVideo";
import { count } from 'rxjs';
import { Alert} from '@mui/material';


export default function MediaRecorder() {

    const VideoPreviewRef = useRef();
    const VideoReplayRef = useRef();
    const RecorderTimerRef = useRef();
    const theme = useTheme();
    const navigate = useNavigate();
    const smallDevices = useMediaQuery(theme.breakpoints.down('md'));
    const [background, setBackground] = useState({ backgroundColor: "#981D97" });
    let [mediaRecorder, SetMediaRecorder] = useState(null);
    let [isRecording, SetIsRecording] = useState(0);
    let [isUploading, SetIsUploading] = useState(false);
    let [fileObj, setFileObj] = useState(null);
    let [percentageUploaded, setPercentageUploaded] = useState(0);
    let [chunks, SetChunks] = useState([]);
    let [videoLink, setVideoLink] = useState(null);
    let [previewUrl, setPreviewUrl] = useState(null);
    let [liveStreamId, setLiveStreamId] = useState(null);
    let [videoCreateId, setVideoCreateId] = useState(null);
    let [appState, setAppState] = useState("IDLE");
    let [progressbarValue, SetProgressbarValue] = useState(0);
    let [openDialog, SetOpenDialog] = useState(false);
    let [openPauseDialog, SetOpenPauseDialog] = useState(false);
    let [openCamiCodeDialog, setOpenCamiCodeDialog] = useState(false);
    let [recordingTimer, setRecordingTimer] = useState(0);
    let [openRecordingOptionsDialog, setOpenRecordingOptionsDialog] = useState(false);
    let [camiTitle, setCamiTitle] = useState('');
    let [camisID, setCamisID] = useState('');
    const [pass, setPass] = useState('');
    const [paid, setPaid] = useState(false);
    const [cameraFront, setCameraFront] = useState(null);
    const [cameraList, setCameraList] = useState('');
    const [facingModeSupported, setFacingModeSupported] = useState(true);
    const [currentEmail, setCurrentEmail] = useState("");
    const [firstName, setFirstName] = useState("")
    const [id, setId] = useState("")
    const [apiData, setApiData] = useState('');
    const [theItemID, setTheItemID] = useState('');
    const [mainCam, setMainCam] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const[durationValue,setDurationValue]=useState(0);
    let[recordCount,setRecordCount] = useState(-1);
    let [camiDesc, setCamiDesc] = useState('');
    const [camiId, setCamiId] = useState(null);
    const [seconds, setSeconds] = useState(3);
    const [isActive, setIsActive] = useState(false);
    const [isStopped, setIsStopped] = useState(false);
    let [rbVariant, setRbVariant] = useState('');
    let [rbValue, setRbValue] = useState(0);
    let [isPaused, setIsPaused] = useState(true);
    let [replayStart, setReplayStart] = useState(false);
    let [cameraState, setCameraState] = useState(true);
    let [clientSecret, setClientSecret] = useState("");
    const [videoId, setVideoId] = useState(null);
    const [videoLength, setVideoLength] = useState(0);
    const [videoSize, setVideoSize] = useState("");
    const [showAlert , setShowAlert] = useState(false);
    const [camiTypeId, setCamiTypeId] = useState("");
    const [online, setOnline] = useState(window.navigator.onLine);
    const [uploadTask, setUploadTask] = useState(null);

    let stripePromise = loadStripe("pk_test_51KzwegAfbpe3qNzlEAM3RafjyKR1yg6KfcmPvUsbsoL85l2HhNMbjakJBJuPmuNMbRiO6NDWC0IFDgqnglUcZuvI00JfTJUM2I");
    // let stripePromise = loadStripe("pk_live_51KzwegAfbpe3qNzleePHdOPEzh972xmDGFvyPzJ2joGCueN2BE7DsuFbBiQ4tvFMpkRxvZ9IwPQVXV9AhSmrPBkt00NwVeksJc");
    const stripe_appearance = { theme: 'stripe' };
    const stripe_options = {
        clientSecret: clientSecret,
        theme: 'stripe'
    };
    const [cardData, setCardData] = useState("");
    const [pmId, setPmId] = useState("");
    const [brand, setBrand] = useState("");
    const [expMonth, SetExpMonth] = useState("");
    const [expYear, setExpYear] = useState("");
    const [cardNumber, setCardNumber] = useState("");
    const [isFree, setIsFree] = useState("");

    // const [progress, setProgress] = React.useState(10);
    document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === 'visible') {
          mediaRecorder.start();
        } else {
           navigate('/cami-steps')
        }
      });
    useEffect(constructor, []);
    useEffect(() => {
        if (mediaRecorder !== null) {
            mediaRecorder.onpause = () => { }
            mediaRecorder.onerror = () => { }
            mediaRecorder.ondataavailable = (e) => {
                let dataChunk = e.data;
                SetChunks([...chunks, dataChunk]);
            }
        }
    }, [mediaRecorder]);
    useEffect(() => {
        if (mediaRecorder !== null && mediaRecorder.state === 'inactive' && chunks.length > 0) {
            let mimeType = getSupportedMedia();
            let blob = new Blob(chunks, { 'type': mimeType });
            let videoURL = window.URL.createObjectURL(blob);
            localStorage.setItem("VIDEOURL", videoURL);
            VideoReplayRef.current.src = videoURL;
            VideoReplayRef.current.autoPlay = false;
            SetOpenDialog(true);
            stopCameraFeed();
            let fileObject = new File(chunks, "CAMITagVideo");
            recordComplete({
                "previewUrl": videoURL,
                "liveStreamId": '',
                "state": "PREVIEW",
                "fileObj": fileObject
            });
        }
    }, [chunks]);
    useEffect(() => {
        if (isRecording === 2) {
            previewPlayerEventListener();
        }
    }, [isRecording]);

    useEffect(() => {
        const handleOnline = () => {
          if (!online && isUploading) {
            uploadVideo();
          }
          setOnline(true);
        };
    
        const handleOffline = () => {
          setOnline(false);
          uploadTask.cancel();
          // You can perform actions when the device goes offline.
        };
    
        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);
      }, []);

    const mainColor = "#981D97";


    const VideoPreviewStyleSM = {
        width: '100vw',
        height: window.innerHeight,
        objectFit: 'cover'
    };
    const VideoPreviewStyleMD = {
        width: '50vw',
        height: 'calc(100vh - 68px)',
        objectFit: 'cover'
    };
    const RecordButtonStyle = {
        transform: 'scale(2)',
        position: 'absolute',
        bottom: '10px',
        left: 'calc(50% - 20px)',
        zIndex: '1201'
    };
    const recordBtnContainer = {
        position: 'absolute',
        bottom: '1px',
        zIndex: '1050',
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: smallDevices ? ('100vw') : ('50vw'),
        height: '100px'
    };
    const recordBtnContainerReplay = {
        position: 'absolute',
        bottom: '1px',
        zIndex: '1051',
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: smallDevices ? ('100vw') : ('50vw'),
        height: '100px'
    };
    const recordBtnInnerContainer = {
        m: 1,
        position: 'relative',
        margin: '0 auto',
        height: '100%'
    };
    const recordBtnInnerContainerReplay = {
        m: 1,
        position: 'relative',
        margin: '0 auto',
        height: '100%'
    };
    const fabButton = {
        position: 'absolute',
        left: '-28px',
        top: '0px',
        border: "4px solid",
        borderColor: (isRecording === 1) ? ("transparent") : ("white"),
        borderRadius: "50%",
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: "transparent",
        },
    }
    const fabButton2 = {
        display: 'flex',
        textAlign: 'center',
        position: 'absolute',
        bottom: 44,
        right: 25,
        zIndex: "1050",
        border: "4px solid",
        borderColor: "transparent",
        borderRadius: "50%",
        // backgroundColor: "transparent",
        backgroundColor: mainColor,
        "&:hover": {
            backgroundColor: mainColor,
        },
    }

    const circularProgress = {
        color: background.backgroundColor,
        position: 'absolute',
        zIndex: 1,
        left: '-31px',
        top: '-2px',
        transform: "rotateY(180deg) rotateZ(-90deg)  !important",

        // transform: "rotateY(180deg)
        // transform: "rotateZ(-90deg) 
    };
    const btnStyle = {
        display: 'block',
        margin: '10px auto',
        width: '40%',
        backgroundColor: mainColor,
        borderRadius: 20
    };

    const paymentStyle = {
        width: '100%',
        mt: 2,
        backgroundColor: "#ecedee",
        paddingBlock: 1,
        paddingInlineStart: 1,
        paddingInlineEnd: 1,
        borderRadius: 1
    }

    const dialogButtons = {
        display: 'block',
        margin: '10px auto',
        width: '60%',
        borderRadius: 20
    }


    const CountDownStyleStyleMD = {
        color: "white",
        fontSize: "45px",
        fontWeight: "bold",
        backgroundColor: "transparent",
        display: isActive ? "flex" : "none"
    }
    const CountDownStyleStyleSM = {
        color: "white",
        fontSize: "45px",
        fontWeight: "bold",
        textAlign: "center",
        backgroundColor: "transparent"
    }
    const CustomButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText("#000"),
        backgroundColor: mainColor,
        "&:hover": {
            backgroundColor: "#bf3bbe",
        },
    }));

    useEffect(() => {
        const auth = getFirebaseAuthModule();
        auth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken().then((newToken) => {
                    localStorage.setItem("JWTTOKEN", newToken);
                    getUserEmailFromUid(user.uid);
                    setCurrentEmail(user.email);
                }).catch((err) => {
                });
            } else if (user = undefined || null) {
            }
        }
        );
    }, [])

    //get user info - user ID, current email Id, Name
    const getUserEmailFromUid = async (authId) => {
        const response = await getUserByAuthId(authId)
            .then(response => {
                setId(response.id)
                setCurrentEmail(response.email);
                setFirstName(response.firstName)
            }).catch(error => {})
    }

    useEffect(() => {
        //get caerd details of current user using user ID, current Email and firstname
        if (id && currentEmail && firstName) {
            getCardDetailsFn();
        }
    }, [id, currentEmail, firstName])

    const getCardDetailsFn = (e) => {
        if (id && firstName && currentEmail) {
            const _getCardDetail = {
                "id": id,
                "name": firstName,
                "email": currentEmail
            };
            getCardDetails(_getCardDetail)
                .then((response) => {
                    setCardData(response)
                }).catch((error) => {
                    setCardData("false")
                })
        } else {
            setCardData("false")
        }
    }


    function getCAMIcodeFromQuery() {
        const urlSearchParams = new URLSearchParams(window.location.search.toString().slice(1));
        if (urlSearchParams.has('p')) {
            const _pass = urlSearchParams.get('p').replace(/ /g, '');
            const camiCodeId = getCamitagFromUrl();
            if (camiCodeId && _pass) {
                setCamiId(camiCodeId);
                getCamiCode(camiCodeId, _pass).then((response) => {
                    if (response["duration"]) {
                        var _duration = (response["duration"] * 1000);
                        setRecordingTimer(_duration);
                    } else if (response['id']) {
                        setCamisID(response['id'])
                    }
                    else {
                        setRecordingTimer(29000);
                    }
                }).catch((error) => {
                    setRecordingTimer(29000);
                })
            }
        }
    }


    function getPasswordFromQuery() {
        const urlSearchParams = new URLSearchParams(window.location.search.toString().slice(1));
        if (urlSearchParams.has('p')) {
            const _pass = urlSearchParams.get('p').replace(/ /g, '');
            setPass(_pass);
        }
    }

    useEffect(() => {
        getPaymentInformation();
    }, [pass])

    useEffect(() => {
        const paid = (localStorage.getItem('P')) ? (localStorage.getItem('P')) : ('');
        localStorage.removeItem('P');
    }, [])

    function getPaymentInformation() {
        if (pass && camiId) {
            let api = baseURL + '/api/CamiCode/VerifyCamiCode?';
            api += 'camiCode=' + camiId + '&uniqueId=' + pass;
            const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
            fetch(api, {
                method: "GET",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
            }).then((response) => response.json()).then((result) => {
                if (result['isPaid'] == true) {
                    setPaid(true);
                } else {
                    setPaid(false);
                }
                setCamiTypeId(result['camiTypeId'])
                setIsFree(result['isFree']);
            })
        }
    }

    useEffect(() => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return setFacingModeSupported(true)
        }
        else return setFacingModeSupported(false)
    }, [])


    let getCamitagFromUrl = () => {
        return getCamitag(window.location);
    }

    let getCamitag = (url) => {
        try {
            const urlparams = new URLSearchParams(url.search);
            const _camitagid = urlparams.get('c') || "";
            return _camitagid;
        } catch (error) {
            return "";
        }
    }


    function stopCameraFeed() {
        if (mediaRecorder !== null) {
            if (mediaRecorder.state == 'recording' || mediaRecorder.state == 'paused') {
                mediaRecorder.stop();
            }
        }
        window.stream.getTracks().forEach((track) => {
            track.stop();
        });
    }

    function constructor() {
        initiateCameraStream();
        getCAMIcodeFromQuery();
        getPasswordFromQuery();
        getRecordingTimeFromQuery();
        return () => {
            stopCameraFeed();
        };
    }

    useEffect(() => {
        if (recordingTimer && firstName && currentEmail) {
            if (cardData == 'false') {
                //looged in flow
                createPaymentIntent();
            } else {
                createPaymentIntent();
            }
        } else if (recordingTimer) {
            //logged out flow
            createPaymentIntent();
        }

    }, [recordingTimer, cardData, firstName, currentEmail]);

    useEffect(() => {
        if (!firstName && !currentEmail) {
            getCardDetailsFn();
        }

    }, [recordingTimer]);

    useEffect(() => {
        if (recordingTimer && !firstName && !currentEmail) {

            if (cardData == 'false') {
                //looged in flow
                createPaymentIntent();
            }
        }
    }, [cardData])

    function createPaymentIntent() {
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        const itemId = (recordingTimer == 29000) ? (1) : (2);
        setTheItemID(itemId);
        if (itemId && firstName && currentEmail) {
            fetch(baseURL + "/api/stripe/create-payment-intent-new", {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    items: {
                        id: itemId,
                        name: firstName,
                        email: currentEmail
                    }
                })
            }).then((res) => res.json())
                .then((data) => {
                    setClientSecret(data.clientSecret)
                });
        } else if (itemId && !firstName && !currentEmail) {
            fetch(baseURL + "/api/stripe/create-payment-intent", {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    items: {
                        id: itemId,
                        name: "",
                        email: ""
                    }
                })
            }).then((res) => res.json())
                .then((data) => {
                    setClientSecret(data.clientSecret)
                });
        }
        // if(localStorage.getItem('CMBMDT')===true){
        //     itemId = 3;
        // }
    }

    function getRecordingTimeFromQuery() {
        const recordingTime = getRecordingTimeFromLS();
        if (recordingTime) {
            setRecordingTimer(recordingTime);
        }
    }


    function previewPlayerEventListener() {
        VideoReplayRef.current.addEventListener('ended', (event) => {
            setIsPaused(!isPaused);
            SetOpenDialog(true);
        });
    }

    function recordComplete(ev) {
        if (ev.videoLink) {
            setVideoLink(ev.videoLink);
        }
        if (ev.previewUrl) {
            setPreviewUrl(ev.previewUrl);
        }
        if (ev.liveStreamId) {
            setLiveStreamId(ev.liveStreamId);
        }
        if (ev.videoId) {
            setVideoId(ev.videoId);
        }
        if (ev.fileObj) {
            setFileObj(ev.fileObj)
        }
    }

    function handleSendIt() {
        SetOpenDialog(false);
        setOpenCamiCodeDialog(true);
    }
    
    function uploadVideo(event) {
        const _subject = event['paymentDetails']['subject'] || '';
        const _vEmail = event['paymentDetails']['videoEmail'] || '';
        const _vName = event['paymentDetails']['videoName'] || '';
        setCamiTitle(_subject);
        SetIsUploading(true);
        uploadRecordedVideoBusiness(fileObj)
            .subscribe({
                next: (res) => {
                    if (res['event']) {
                        switch (res['event']) {
                            case 'progressbar':
                                if (res['progress'] == 100) {
                                    setPercentageUploaded(null);
                                } else {
                                    setPercentageUploaded(res['progress']);
                                }
                                break;
                            case 'complete':
                                setPercentageUploaded(null);
                                if (res['response']['videoId']) {
                                    // setVideoCreateId(res['response']['videoId'])
                                    setIsLoading(true);
                                     getDuration(res['response']['videoId'],event) 
                                  
                                   
                                } else {
                                }
                                break;
                            case 'error':
                                break;
                        }
                    }
                },
                error: (err) => {

                },
                complete: (comp) => { }
            });
    }

    // useEffect(() => {
    //     getDuration()
    // }, [videoCreateId])

    function getDuration(_videoId,event) {
        getBearerToken()
            .then((response) => {
                    getVideoStatus(response, _videoId)
                        .then((result) => {
                            mapCamitagForVideo(_videoId,event,result['encoding']['metadata']['duration'],result['ingest']['filesize']);

                        })
                        .catch((error) => {
                        });
                
            })
            .catch((error) => {
            });
    }
   

    async function getCAMIsID(camiId) {
        const result = await getCamiCode(camiId, pass)
        setCamiTypeId(result["camiTypeId"]);
        return result?.id;
    }
    async function mapCamitagForVideo(_videoId,event,_videoLength,_videoSize) {
        const _subject = event['paymentDetails']['subject'] || '';
        const _vEmail = event['paymentDetails']['videoEmail'] || '';
        const _vName = event['paymentDetails']['videoName'] || '';
        const auth = getFirebaseAuthModule();
        let authId = '';
        if (auth['currentUser']) {
            if (auth['currentUser']['uid']) {
                authId = auth['currentUser']['uid'];
            }
        }
        const camitagid = (localStorage.getItem('P')) ? (localStorage.getItem('P')) : ('');
        setCamiId(camitagid)
        localStorage.removeItem('P');
        const userData = (authId) ? (await getUserByAuthId(authId)) : ('');
        let userId = 0;
        if (!(userData['status'] === 'error')) {
            userId = userData['id'];
        }
        const data = {
            "camiTypeId": camiTypeId != ""?camiTypeId :0 ,
            "imageURI": "",
            "videoId": _videoId,
            "duration": recordingTimer / 1000,
            "title": camiTitle,
            "subject": _subject,
            "camiCreatedBy": 0,
            "videoCreatedBy": userId,
            "accessRights": 0,
            "recordStatus": true,
            "vName": _vName,
            "vEmail": _vEmail,
            "isPaid": true,
            "isFree": false,
            "freeFor": 0,
            "createdBy": 0,
            "qrImage": "",
            "videoLength": _videoLength ? _videoLength : recordCount ,
            "videoSize": _videoSize.toString()
        }
        if (camiId && paid == true || isFree) {
            const userData = (authId) ? (await getUserByAuthId(authId)) : ('');
            let userId = 0;
            if (!(userData['status'] === 'error')) {
                userId = userData['id'];
            }
            getCAMIsID(camiId).then((resp_camiID) => {

                // const camisID = localStorage.getItem('IDOFCAMI')
                // const { isPaid, ...updateData } = { ...data, camiCode: camitagid }
                const updateData = {
                    "id": resp_camiID,
                    "camiTypeId": camiTypeId != ""?camiTypeId:0,
                    "imageURI": "",
                    "videoId": _videoId,
                    "duration": recordingTimer / 1000,
                    "title": camiTitle,
                    "description": _subject,
                    "camiCreatedBy": 0,
                    "videoCreatedBy": userId,
                    "accessRights": 0,
                    "recordStatus": true,
                    "vName": _vName,
                    "vEmail": _vEmail,
                    "isPaid": true,
                    "isFree": isFree,
                    "createdBy": 0,
                    "qrImage": "",
                    "videoLength": _videoLength ? _videoLength : recordCount,
                    "videoSize": videoSize.toString(),
                    "qrImage":''
                }
                updateData['camiCode'] = camiId;
                UpdateCAMIApi(updateData)
                    .then((result) => {
                        if(camiTypeId == 5){
                            setIsLoading(false)
                           setShowAlert(true)
                        }else{
                            navigate("/camicard?c=" + camiId + '&p=' + pass + "&ps=success " + '&result=update_success');
                            localStorage.removeItem('IDOFCAMI')
                        }  
                        // let camitagid = result || "";
                        // navigate("/camicard?c=" + camiId + '&p=' + pass + "&ps=success " + '&result=update_success');
                        // localStorage.removeItem('IDOFCAMI')

                    })
                    .catch((error) => {
                        setAppState("APPROVED");
                    });
            })
        } else {
            mapCamitagAndVideo(data)
                .then((result) => {
                    let camitagid = result.camiId || "";
                    let pass = result.camiPass || "";
                    navigate("/camicard?c=" + camitagid + '&p=' + pass + "&ps=success");
                })
                .catch((error) => {
                    setAppState("APPROVED");
                });
        }
        setIsLoading(false)
        // localStorage.removeItem('CCRECORD');
    }

    function switchCamera() {
        // const tracks = window.stream.getTracks();
        // window['stream'].onremovetrack = (event) => {
        //     if (typeof event.track.id !== "undefined") {
        //         if (arr.indexOf(event.track.id)>-1) {
        //             const _index = arr.indexOf(event.track.id);
        //             delete arr[_index];
        //             if (arr.length ==0) {
        //                 // all tracks are stopped ...
        //             }
        //         }
        //     }
        // };
        // const tracks = window.stream.getTracks();
        // tracks.forEach((track) => {
        //     track.stop();
        // });
        // stopCameraFeed();


        // }
        if (cameraFront == null) {
            setCameraFront(false);
        } else if (cameraFront == false) {
            setCameraFront(true)
        } else if (cameraFront == true) {
            setCameraFront(false)
        }
    }

    // function getDeviceIds() {
    //     if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    //         return;
    //     }
    //     navigator.mediaDevices.enumerateDevices()
    //         .then(function (devices) {
    //             devices.forEach(function (device) {
    //                 if (device['kind'] == 'videoinput') {
    //                     setCameraList(device.label)
    //                 }
    //             });
    //         })
    //         .catch(function (err) {
    //         });
    //     // return(
    //     // <button style={{ display: 'block', position: 'absolute', bottom: 25, right: 25, zIndex: 1500 }} onClick={switchCamera} >Switch Camera</button>
    //     // )
    // }

    useEffect(() => {
        // getDeviceIds();
        // setTimeout(initiateCameraStream, 5000);
        if (cameraFront == true) {
            // initiateUserCameraStream();
            setTimeout(initiateUserCameraStream, 1000);
        } else if (cameraFront == false) {
            // initiateEnvCameraStream();
            setTimeout(initiateEnvCameraStream, 1000);
        }
    }, [cameraFront]);


    function initiateUserCameraStream() {

        if (window['stream']) {
            const tracks = window.stream.getTracks();
            tracks.forEach((track) => {
                setMainCam('mainCam');
                track.stop();
            });
        }
        const isFacingModeSupported = identifyFacingModeSupport();
        if (isFacingModeSupported == true) {
            requestMedia({
                audio: {
                    echoCancellation: true
                },
                video: {
                    facingMode: 'user',
                }
            })
                .then((res) => {
                    if (res['type'] === 'success') {
                        const stream = res['response']['streamObject'];
                        SetMediaRecorder(res['response']['mediaRecorder']);
                        window['stream'] = stream;
                        displayStreamPreview(stream);
                    } else {
                        setCameraState(false);
                    }
                })
                .catch((error) => {
                    setCameraState(false);
                });
            return () => {
                stopCameraFeed();
                const tracks = window.stream.getTracks();
                tracks.forEach((track) => {
                    setMainCam('');
                    track.stop();
                });
            };
        } else if (isFacingModeSupported == null || undefined || false) {
            const _CONSTRAINTS = getVideoConstraints();
            requestMedia(_CONSTRAINTS)
                .then((res) => {
                    if (res['type'] === 'success') {
                        const stream = res['response']['streamObject'];
                        SetMediaRecorder(res['response']['mediaRecorder']);
                        window['stream'] = stream;
                        displayStreamPreview(stream);
                        // setMainCam('mainCam');
                    } else {
                        setCameraState(false);
                    }
                })
                .catch((error) => {
                    setCameraState(false);
                });
            return () => {
                stopCameraFeed();
                const tracks = window.stream.getTracks();
                tracks.forEach((track) => {
                    track.stop();
                });
            };
        }
    }
    function initiateEnvCameraStream() {
        if (window['stream']) {
            const tracks = window.stream.getTracks();
            tracks.forEach((track) => {
                setMainCam('');
                track.stop();
            });
        }

        const isFacingModeSupported = identifyFacingModeSupport();


        if (isFacingModeSupported == true) {
            requestMedia({
                audio: {
                    echoCancellation: true
                },
                video: {
                    facingMode: 'environment',
                }
            })
                .then((res) => {
                    if (res['type'] === 'success') {
                        const stream = res['response']['streamObject'];
                        SetMediaRecorder(res['response']['mediaRecorder']);
                        window['stream'] = stream;
                        displayStreamPreview(stream);
                    } else {
                        setCameraState(false);
                    }
                })
                .catch((error) => {
                    setCameraState(false);
                });
            return () => {
                stopCameraFeed();
                const tracks = window.stream.getTracks();
                tracks.forEach((track) => {
                    setMainCam('mainCam');
                    track.stop();
                });
            };

        } else if (isFacingModeSupported == null || undefined || false) {
            // stopCameraFeed();
            const tracks = window.stream.getTracks();
            tracks.forEach((track) => {
                track.stop().then(() => {
                    const _CONSTRAINTS = getVideoConstraints();
                    requestMedia(_CONSTRAINTS)
                        .then((res) => {
                            if (res['type'] === 'success') {
                                const stream = res['response']['streamObject'];
                                SetMediaRecorder(res['response']['mediaRecorder']);
                                window['stream'] = stream;
                                displayStreamPreview(stream);
                            } else {
                                setCameraState(false);
                            }
                        })
                        .catch((error) => {
                            setCameraState(false);
                        });

                })
            });

            return () => {
                stopCameraFeed();
                const tracks = window.stream.getTracks();
                tracks.forEach((track) => {
                    track.stop();
                });
            };
        }
    }
    function initiateCameraStream() {

        requestMedia()
            .then((res) => {
                setMainCam('mainCam');
                if (res['type'] === 'success') {
                    const stream = res['response']['streamObject'];
                    SetMediaRecorder(res['response']['mediaRecorder']);
                    window['stream'] = stream;
                    displayStreamPreview(stream);
                } else {
                    setCameraState(false);
                }
            })
            .catch((error) => {
                setCameraState(false);
            });
        return () => {
            stopCameraFeed();
            const tracks = window.stream.getTracks();
            tracks.forEach((track) => {
                setMainCam('');
                track.stop();
            });
        };
    }

    function handleRecording(recordingTime) {
        const startStream = () => {
            window['seconds'] = 3;
            setIsActive(true);
            setIsStopped(false)
            // setIsRecording(2);
            window['timerInterval'] = setInterval(() => {
                if (window.seconds - 1 <= 0) {
                    clearInterval(window.timerInterval);
                } else {
                    window.seconds -= 1;
                    setSeconds(window.seconds);
                }
            }, 1000);
            setTimeout(() => {
                setSeconds(3);
                SetChunks([]);
                mediaRecorder.start();
                setRecordingTimer(recordingTime);
                SetIsRecording(1);
                setOpenRecordingOptionsDialog(false);
                setRbValue(0);
                setRbVariant('determinate');
                RecorderTimerRef.current.startTimer(recordingTime);
                setIsActive(false);
            }, 3000);
        }
        if (isRecording === 0) {
            startStream();
        } else if (isRecording === 1) {
            setIsStopped(true)
            mediaRecorder.stop();
            // setRecordingTimer(0);
            SetIsRecording(2);
            setRbValue(calculatePercentage(0));
            setRbVariant('indeterminate');
            RecorderTimerRef.current.stopTimer();
        }
    }
    function calculatePercentage(val) {
        const _rc = Number(recordingTimer) / 1000;
        let timerPerc = ((Number(val) / _rc) * 100);
        setRecordCount(Number(recordCount + 1));
        return ((timerPerc));
    }
    function handleRecordingOptionsDialog() {

    }
    function displayRecordingOptions() {
        if (isRecording === 1) {
            setIsStopped(false)
            handleRecording(0);
        } else {
            if (recordingTimer === 0) {
                setOpenRecordingOptionsDialog(true);
            } else {
                handleRecording(recordingTimer);
            }
        }
    }
    function handleProgressBar() {

    }

    function CircularProgressWithLabel(props) {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress size={70} variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography sx={{ fontSize: "22px" }} variant="caption" component="div" color="black">
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        );
    }

    CircularProgressWithLabel.propTypes = {
        /**
         * The value of the progress indicator for the determinate variant.
         * Value between 0 and 100.
         * @default 0
         */
        value: PropTypes.number.isRequired,
    };


    function displayStreamPreview(streamObject) {
        if (typeof VideoPreviewRef.current !== 'undefined' && typeof VideoPreviewRef.current.srcObject !== 'undefined') {
            VideoPreviewRef.current.muted = true;
            VideoPreviewRef.current.srcObject = streamObject;
        } else {
            VideoPreviewRef.current.src = window.URL.createObjectURL(streamObject);
        }
    }
    function stopRecordingTimer() {
        if (isRecording === 1) {
            handleRecording(0);
        }
    }
    function handleDialogClose() { }
    function handleCamiCodeDialogClose() { }
    function replayVideo() {
        setReplayStart(true)
        SetOpenDialog(false);

        setIsPaused(false);
        const progress = (VideoReplayRef.current.currentTime / VideoReplayRef.current.duration) * 100;
        if (progress !== 100) {
            VideoReplayRef.current.currentTime = 0;
            VideoReplayRef.current.play();
        }
        else {
            VideoReplayRef.current.play();
        }


    }
    function reRecordVideo() {
        SetChunks([]);
        SetIsRecording(0);
        SetOpenDialog(false);
        initiateCameraStream();
    }
    function renderVideoTag() {
        let videoPreviewStyle;
        if (smallDevices) {
            videoPreviewStyle = VideoPreviewStyleSM;
        } else {
            videoPreviewStyle = VideoPreviewStyleMD;
        }
        var camErrMessage = {
            textAlign: 'center',
            width: '100%',
            border: '2px solid #d9fbf5',
            color: '#ef667e',
            fontWeight: 800,
            marginTop: '250px',
            // height:'100px',
            lineHeight: '30px'
        };
        if (cameraState === false) {
            return <div style={camErrMessage} >Your browser settings does not allow CAMI application to use camera. Please update the settings.</div>
        } else if (cameraState === true) {
            return <video id={mainCam} style={videoPreviewStyle} ref={VideoPreviewRef} playsInline autoPlay muted ></video>
        }
    }

    function renderDialogOnPause() {
        // if (isPaused) {
        return (
            <Dialog sx={{ zIndex: "15000", minWidth: 120 }} onClose={handleDialogClose} open={openPauseDialog}  >
                {/* <DialogTitle sx={{ color: background.backgroundColor, fontSize: '1rem' }} >Happy with your message?</DialogTitle> */}
                <Box sx={{ display: 'block', width: '100%', alignItems: 'center', paddingBottom: 1 }} >
                    <CustomButton sx={dialogButtons} variant={'contained'} onClick={replayVideo} >REPLAY</CustomButton>
                    <CustomButton sx={dialogButtons} variant={'contained'} onClick={reRecordVideo} >RE-RECORD</CustomButton>
                    <CustomButton sx={dialogButtons} varint={'contained'} onClick={handleSendIt} >MAKE IT LIVE!</CustomButton>
                </Box>
            </Dialog>
        )

    }
    function playPause() {
        VideoReplayRef.current.pause();
        setIsPaused(true)
        SetOpenDialog(true);

        if (isPaused == true) {
            VideoReplayRef.current.play();
            setIsPaused(!isPaused);
        }
    }

    function renderVideoPreview() {
        let videoPreviewStyle;
        if (smallDevices) {
            videoPreviewStyle = VideoPreviewStyleSM;
        } else {
            videoPreviewStyle = VideoPreviewStyleMD;
        }
        return <>
            <video style={videoPreviewStyle} ref={VideoReplayRef} playsInline ></video>
            {/* { renderDialogOnPause()}  */}

            {/* <Button onClick={playPause }>Stop</Button> */}
            {replayStart == true && <Box sx={recordBtnContainerReplay}  >
                <Box sx={recordBtnInnerContainer} >
                    <Fab sx={fabButton} onClick={playPause}>

                        {isPaused === true && <PlayArrowIcon sx={{ color: mainColor, fontSize: "40px" }} />}
                        {isPaused === false && <StopCircle sx={{ color: mainColor, fontSize: "40px" }} />}


                    </Fab>
                </Box>
            </Box>}
        </>
    }
    function renderRecordButton() {
        if ((camiId && recordingTimer == 0) || cameraState == false) {
            return null
        } else if (camiId && recordingTimer > 0) {
            return (
                <Box sx={recordBtnContainer} >
                    <Box sx={recordBtnInnerContainer} >
                        {(isRecording === 0 || isRecording === 1 && !isActive) && <Fab sx={fabButton} onClick={displayRecordingOptions} >
                            {isActive && countDownRender()}
                            {isRecording === 1 && !isActive && !isStopped && <StopCircle sx={{ color: mainColor }} />}
                            {isRecording === 0 && !isActive && <RadioButtonChecked sx={{ color: mainColor }} />}


                        </Fab>}
                        {(isRecording === 1) && (
                            <CircularProgress size={62} sx={circularProgress} variant={rbVariant} value={rbValue} />
                        )}
                    </Box>
                </Box>
            )
        } else {
            return (
                <Box sx={recordBtnContainer} >
                    <Box sx={recordBtnInnerContainer} >
                        {(isRecording === 0 || isRecording === 1 && !isActive) && <Fab sx={fabButton} onClick={displayRecordingOptions} >
                            {isActive && countDownRender()}
                            {isRecording === 1 && !isActive && !isStopped && <StopCircle sx={{ color: mainColor }} />}
                            {isRecording === 0 && !isActive && <RadioButtonChecked sx={{ color: mainColor }} />}
                        </Fab>}
                        {(isRecording === 1) && (
                            <CircularProgress size={62} sx={circularProgress} variant={rbVariant} value={rbValue} />
                        )}
                    </Box>
                </Box>
            )
        }
    }
    function LinearProgressWithLabel(props) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress color="secondary" variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 37 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }
    function renderConfirmationDialog() {
        if (isRecording === 2) {
            if (!isUploading) {
                return (
                    <Dialog onClose={handleDialogClose} open={openDialog}  >
                        <DialogTitle sx={{ color: background.backgroundColor, fontSize: '1rem' }} >Happy with your message?</DialogTitle>
                        <Box sx={{ display: 'block', width: '100%', alignItems: 'center', paddingBottom: 1 }} >
                            <CustomButton sx={dialogButtons} variant={'contained'} onClick={replayVideo} >REPLAY</CustomButton>
                            <CustomButton sx={dialogButtons} variant={'contained'} onClick={reRecordVideo} >RE-RECORD</CustomButton>
                            <CustomButton sx={dialogButtons} varint={'contained'} onClick={handleSendIt} >MAKE IT LIVE!</CustomButton>
                        </Box>
                    </Dialog>
                )
            }
        } else {
            return null;
        }
    }
    function recordTimerProgress(progressValue) {
        setRbValue(calculatePercentage(progressValue));
        setRbVariant('determinate');
    }
    const countDownRender = () => {
        let countDownStyle = CountDownStyleStyleMD;
        if (smallDevices) {
            countDownStyle = CountDownStyleStyleSM;
        } else {
            countDownStyle = CountDownStyleStyleMD;
        }
        if (smallDevices) {
            return <div style={countDownStyle} className="count-down" id='count-down-custom'>{seconds}</div>
        } else {
            return <div style={countDownStyle} className="count-down" id='count-down-custom'>{seconds}</div>
        }
    }
    function displayProgress() {
        const percentageTrue = ((Math.floor(percentageUploaded) > 0 && (Math.floor(percentageUploaded) < 100)))
        if (percentageTrue == true) {
            return (<Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh"
            }} >
                <CircularProgressWithLabel sx={{ color: mainColor }} value={percentageUploaded} />
            </Box>)
        }
    }
    function paymentStatus(event) {
        setOpenCamiCodeDialog(false);
        uploadVideo(event);
    }
    function recordingTimerOptions() {
        return (<Dialog fullWidth={true}
            onClose={handleRecordingOptionsDialog}
            scroll={'body'}
            open={openRecordingOptionsDialog} >
            <CustomButton
                variant="contained"
                sx={btnStyle}
                onClick={() => { handleRecording(29000) }} >29 seconds</CustomButton>
            <CustomButton
                variant="contained"
                sx={btnStyle}
                onClick={() => { handleRecording(180000) }} >3 minutes</CustomButton>
        </Dialog>);
    }
    const paddingPurchaseLeft =
    {
        display: 'block',
        flexDirection: "column",
        alignItems: 'center',
        paddingLeft: smallDevices ? (1) : (2),
        paddingRight: smallDevices ? (1) : (2),

    }
    function  handleSucessClose(){
        navigate("/forever-cami")
    }
    function recordAndPayOptions() {
        return (<Dialog
            fullWidth={true}
            onClose={handleCamiCodeDialogClose}
            maxWidth={'xs'}
            scroll={'body'}
            open={openCamiCodeDialog} >
            <DialogTitle sx={{ textAlign: "center" }}>
                CAMIcode Details
            </DialogTitle>
            <Box sx={paddingPurchaseLeft}>
                {clientSecret && (<Elements stripe={stripePromise} options={stripe_options} >
                    <CheckoutForm paymentStatus={paymentStatus} paid={paid} card={cardData} itemId={theItemID} secret={clientSecret} camiTypeId={camiTypeId} />
                </Elements>)}
            </Box>
        </Dialog>);
    }
    function recordingTimerComponent() {
        return (<RecordTimer ref={RecorderTimerRef}
            stopRecordingCallback={stopRecordingTimer}
            recordTimerProgress={recordTimerProgress} ></RecordTimer>);
    }
    function uploadProgressComponent() {
        return (<Dialog fullWidth={true}
            sx={{ zIndex: 1235 }}
            onClose={handleDialogClose}
            open={((Math.floor(percentageUploaded) > 0 && (Math.floor(percentageUploaded) < 100)))} >
            <Box sx={{ width: '100%' }} >
                <LinearProgressWithLabel value={percentageUploaded} />
            </Box>
        </Dialog>);
    }
    if (isLoading == true) {
        return (<Box sx={{ width: '100%', textAlign: 'center', paddingTop: '150px' }} >
            <Box sx={{ marginBottom: 3, width: '100%' }} >
                <CircularProgress sx={{ margin: '0 auto' }} />
            </Box>
            <Typography sx={{ fontSize: "35px", color: mainColor }}>Your CAMIcode is getting Generated!</Typography>
        </Box>)
    } else if(showAlert == true){
        return (
          <Box>
          <Box  sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", height: "60vh", maxWidth: "100%", }}>
            <Alert onClose={handleSucessClose} severity="success">
            Your Video is Uploaded successfully.
            </Alert>
          </Box>
        </Box>
        );
      }else if (!online && isUploading) {
        return (
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "60vh",
                maxWidth: "100%",
              }}
            >
              <Typography>
                Connect to the internet You are offline,Check Your connection
              </Typography>
            </Box>
          </Box>
        );
      }
    else return (
        <>
            {(isRecording === 1 || isRecording === 0) && renderVideoTag()}
            {(isRecording === 2) && !isUploading && renderVideoPreview()}
            {recordingTimerOptions()}
            {recordAndPayOptions()}
            {recordingTimerComponent()}
            {/* {uploadProgressComponent()} */}
            {displayProgress()}
            {renderRecordButton()}
            {renderConfirmationDialog()}
            {(isRecording === 0 && !isActive) && smallDevices && facingModeSupported == true && cameraState == true &&
                <Fab sx={fabButton2}>
                    <FlipCameraIosOutlinedIcon style={{ position: 'absolute', bottom: 6, right: 3, fontSize: 40, color: "white" }} onClick={switchCamera} ></FlipCameraIosOutlinedIcon>
                </Fab>
            }
        </>
    );
};

import React, { useEffect } from "react";
import { Paper, Typography, Dialog, DialogTitle } from "@mui/material";
import { borderRadius, Box } from "@mui/system";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";

import step1 from "./../../assets/CamiSteps/step1.png";
import step2 from "./../../assets/CamiSteps/step2.png";
import step3 from "./../../assets/CamiSteps/step3.png";
import step4 from "./../../assets/CamiSteps/step4.png";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";

import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  getQueryParamValue,
  getVideoAvailabilityStatus,
} from "../../Business/MediaBusiness";
import { useMediaQuery, useTheme } from "@mui/material";
import { baseURL } from "../../Repository/Api";
import Link from "@mui/material/Link";
import { getUserByAuthId, getUserByEmail } from "../../Repository/Api";
import { getFirebaseAuthModule } from "../../Repository/Authentication/Firebase";

function CamiSteps() {
  const theme = useTheme();
  const smallDevices = useMediaQuery(theme.breakpoints.down("md"));
  const [option, setOption] = useState("");
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [password, setPassword] = useState("");
  const [freeCamiExist, setFreeCamiExist] = useState(false);
  const [camiforeverExist, setCamiforeverExist] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
    localStorage.setItem("RT", "P1");
    localStorage.removeItem("PAID");
    setOption("1 CAMIcode 29 secs - $3.99");
  };

  const handleClose3 = () => {
    setAnchorEl(null);
    localStorage.setItem("RT", "P2");
    setOption("1 CAMIcode 3 mins - $6.99");
  };

  useEffect(() => {
    verifyCamiCode();
  }, []);

  function verifyCamiCode() {
    const url = window.location.href;
    const urlparams = new URLSearchParams(
      window.location.search.toString().slice(1)
    );
    if (urlparams.has("c") && urlparams.has("p")) {
      const _password = urlparams.get("p");
      setPassword(_password);
      let api = baseURL + "/api/CamiCode/VerifyCamiCode?";
      api +=
        "camiCode=" + urlparams.get("c") + "&uniqueId=" + urlparams.get("p");
      const token = "Bearer " + localStorage.getItem("JWTTOKEN");
      fetch(api, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          let _camicode = result["camiCode"];
          let _duration = result["duration"];
          let _video = result["videoId"];
          let _isPaid = result["isPaid"];
          if (_isPaid == true) {
          }
          if (_duration == "29") {
            localStorage.setItem("RT", "P1");
          } else if (_duration == "180") {
            localStorage.setItem("RT", "P2");
          } else if (!_video || _video == null || "" || undefined) {
            navigate("/upload-video?c=" + _camicode + "&p=" + _password);
          }
          navigate("/watch?c=" + result["camiCode"] + "&p=" + password);
        })
        .catch((error) => {
        });
    }
  }

  const stepColor = "#80CBC4";
  const mainColor = "#981D97";
  const stepsCardStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 2,
    marginBottom: 2,
  };
  const stepsCardStyleSM = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 1,
    marginBottom: 1,
  };
  const stepsContainer = {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginLeft: 15,
    marginRight: 15,
  };

  const dialogButtons = {
    display: "block",
    margin: "10px auto",
    width: "100%",
    borderRadius: 20,
    width: "100%",
  };

  const stepsContainerSM = {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginLeft: 2,
    marginRight: 2,
  };

  const title = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };
  const cardsContentsTwo = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "200px",
    backgroundColor: mainColor,
    borderRadius: "20px",
    height: "120px",
  };
  const cardsContentsTwoSM = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "150px",
    backgroundColor: mainColor,
    borderRadius: "20px",
    height: "100px",
  };
  const step1Style = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };
  const stepTextBlue = {
    color: "white",
    backgroundColor: stepColor,
    width: "70px",
    textAlign: "center",
    alignItems: "flex-start",
    borderRadius: "20px",
    position: "relative",
    bottom: -8,
    left: 15,
  };
  const stepTextBlueSM = {
    color: "white",
    backgroundColor: stepColor,
    width: "55px",
    textAlign: "center",
    alignItems: "flex-start",
    borderRadius: "20px",
    position: "relative",
    bottom: -8,
    left: 15,
    fontSize: "13px",
  };
  const itsEasyStyle = {
    color: mainColor,
    padding: 4,
    fontWeight: "bold",
    fontSize: 20,
  };
  const mainContent = {
    paddingLeft: 2,
    paddingRight: 0,
    color: "#fff",
    width: "100px",
  };

  const mainContent1 = {
    paddingLeft: 2,
    paddingRight: 0,
    color: "#fff",
    width: "115px",
  };

  const mainContent1SM = {
    paddingLeft: 2,
    paddingRight: 0,
    color: "#fff",
    width: "90px",
    fontSize: "12px",
  };

  const recipientStyle = {
    color: "white",
    display: "flex",
    fontWeight: "600",
    alignItems: "center",
    justifyContent: "center",
    width: "400px",
    backgroundColor: mainColor,
    borderRadius: 8,
    padding: 1,
  };

  const recipientStyle1 = {
    color: "white",
    display: "flex",
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "center",
    width: "400px",
    padding: 1,
    backgroundColor: mainColor,
    color: "white",
    "&:hover": {
      backgroundColor: "#bf3bbe",
    },
    textTransform: "capitalize",
  };

  const recepientContainer = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: 5,
    marginBottom: 3,
  };

  const recepientContainer2 = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
    marginTop:2,
    marginBottom: 5,
  };
  const getStarted = {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 5,
    marginBottom: 10,
  };
  const getStartedText = {
    backgroundColor: stepColor,
    color: "white",
    padding: 2,
    width: "170px",
    textAlign: "center",
    borderRadius: 25,

    "&:hover": {
      backgroundColor: "#89d7d1",
    },
  };

  const foreverRecipientStyle={
    color: "white",
    display: "flex",
    fontWeight: "600",
    alignItems: "center",
    justifyContent: "center",
    width: "400px",
    backgroundColor: stepColor,
    borderRadius: 8,
    paddingTop:0, 
  }

  const foreverStyleText={
    fontSize:12,
    fontWeight: "600",
    color:"white"
  }

  const numberStyle = {
    fontSize: 25,
  };

  const menuStyle = {
    width: "250px",
    alignSelf: "center",
    color: "#000",
  };
  const menuContainer = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const handleUploadNavigate = () => {
    navigate("/upload-video");
  };

 const handleForeverNavigate=()=>{
    navigate("/forever-cami")
 }

  const CustomButton = styled(Button)(({ theme }) => ({
    backgroundColor: mainColor,
    "&:hover": {
      backgroundColor: "#bf3bbe",
    },
  }));

  useEffect(() => {
    if (localStorage.getItem("RT")) {
      if (localStorage.getItem("RT") == "P1") {
        setOption("1 CAMIcode 29 secs - $3.99");
      }
      if (localStorage.getItem("RT") == "P2") {
        setOption("1 CAMIcode 3 mins - $6.99");
      }
    }
  }, []);
  useEffect(() => {
    const auth = getFirebaseAuthModule();
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((newToken) => {
          localStorage.setItem("JWTTOKEN", newToken);
          getFreeCami(user.uid);
        });
      } else {
        return (error) => {

        }
      }
    });
  }, []);

  const getFreeCami = async (user) => {
    const res = await getUserByAuthId(user)
      .then((response) => {
        if (response["freeCamiCodes"]) {
          if (response["freeCamiCodes"] > 0) {
            setFreeCamiExist(true);
          } else {
            setFreeCamiExist(false);
          }
        }
        if (response["foreverCamiCodes"] > 0) {
          setCamiforeverExist(true);
        } else {
          setCamiforeverExist(false);
        }
      })
      .catch((error) => {
        
      });
  };

  return (
    <Box>
      <Box sx={title}>
        <Typography sx={itsEasyStyle}>IT'S EASY!</Typography>
      </Box>
      {smallDevices && (
        <Box sx={stepsContainerSM}>
          <Box sx={stepsCardStyleSM}>
            <Typography sx={stepTextBlueSM}>Step 1</Typography>
            <Box sx={cardsContentsTwoSM}>
              <Typography sx={mainContent1SM}>
                record + approve your video.
              </Typography>
              <img
                style={{
                  height: "90px",
                  paddingRight: "8px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
                src={step1}
              ></img>
            </Box>
          </Box>
          <Box sx={stepsCardStyleSM}>
            <Typography sx={stepTextBlueSM}>Step 2</Typography>
            <Box sx={cardsContentsTwoSM}>
              <Typography sx={mainContent1SM}>
                purchase your CAMIcode
              </Typography>
              <img
                style={{
                  height: "90px",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  paddingBottom: "8px",
                }}
                src={step2}
              ></img>
            </Box>
          </Box>
          <Box sx={stepsCardStyleSM}>
            <Typography sx={stepTextBlueSM}>Step 3</Typography>
            <Box sx={cardsContentsTwoSM}>
              <Typography sx={mainContent1SM}>print your CAMIcode</Typography>
              <img
                style={{
                  height: "70px",
                  borderRadius: "20px",
                  width: "70px",
                  paddingRight: "5px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
                src={step3}
              ></img>
            </Box>
          </Box>
          <Box sx={stepsCardStyleSM}>
            <Typography sx={stepTextBlueSM}>Step 4</Typography>
            <Box sx={cardsContentsTwoSM}>
              <Typography sx={mainContent1SM}>apply to any gift.</Typography>
              <img
                style={{
                  height: "80px",
                  borderRadius: "20px",
                  width: "81px",
                  paddingRight: "5px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
                src={step4}
              ></img>
            </Box>
          </Box>
        </Box>
      )}
      {!smallDevices && (
        <Box sx={stepsContainer}>
          <Box sx={stepsCardStyle}>
            <Typography sx={stepTextBlue}>Step 1</Typography>
            <Box sx={cardsContentsTwo}>
              <Typography sx={mainContent1}>
                record + approve your video.
              </Typography>
              <img
                style={{
                  height: "100px",
                  paddingRight: "5px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
                src={step1}
              ></img>
            </Box>
          </Box>
          <Box sx={stepsCardStyle}>
            <Typography sx={stepTextBlue}>Step 2</Typography>
            <Box sx={cardsContentsTwo}>
              <Typography sx={mainContent}>purchase your CAMIcode</Typography>
              <img
                style={{
                  height: "100px",
                  paddingRight: "5px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
                src={step2}
              ></img>
            </Box>
          </Box>
          <Box sx={stepsCardStyle}>
            <Typography sx={stepTextBlue}>Step 3</Typography>
            <Box sx={cardsContentsTwo}>
              <Typography sx={mainContent}>print your CAMIcode</Typography>

              <img
                style={{
                  height: "115px",
                  borderRadius: "20px",
                  width: "100px",
                  paddingRight: "5px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
                src={step3}
              ></img>
            </Box>
          </Box>
          <Box sx={stepsCardStyle}>
            <Typography sx={stepTextBlue}>Step 4</Typography>
            <Box sx={cardsContentsTwo}>
              <Typography sx={mainContent}>apply to any gift</Typography>
              <Box sx={{ padding: 1 }}>
                <img
                  style={{ height: "90px", width: "90px" }}
                  src={step4}
                ></img>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Box sx={recepientContainer}>
        <Typography sx={recipientStyle}>
          recipient scans for a smile
          <SentimentSatisfiedAltRoundedIcon
            sx={{ fontSize: 10, paddingLeft: 2, height: 30, width: "54px" }}
          />
        </Typography>
      </Box>
      {camiforeverExist == true && (
        <Box
          sx={{
            color: "#981D97",
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            paddingTop: 2,
          }}
        >
          {/* <Link
                    href="/forever-cami"
                    variant="h6"
                    color="inherit"
                  >
                   Click here to use your CAMI-Forever codes
                </Link> */}
          <Typography sx={foreverRecipientStyle}>
            <Button sx={foreverStyleText} onClick={handleForeverNavigate} >
              Click here to use your CAMIforever codes
            </Button>
          </Typography>
        </Box>
      )}
      {freeCamiExist == true && (
        <Box
          sx={{
            color: "#981D97",
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            paddingTop: 2,
          }}
        >
          <Link href="/free-cami" variant="h6" color="inherit">
            Click here to use your free CAMIcodes
          </Link>
        </Box>
      )}

      <Box sx={recepientContainer2}>
        {!option && (
          <Box sx={menuContainer}>
            <Button
              variant="outlined"
              id="fade-button"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{
                borderColor: "#000",
                color: "#000",
                "&:hover": {
                  borderColor: "#000",
                },
                textTransform: "capitalize",
                width: "250px",
              }}
              endIcon={<ArrowDropDownIcon />}
            >
              Choose your option
            </Button>
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem sx={menuStyle} onClick={handleClose1}>
                1 CAMIcode 29 secs - $3.99
              </MenuItem>
              <MenuItem sx={menuStyle} onClick={handleClose3}>
                1 CAMIcode 3 mins - $6.99
              </MenuItem>
            </Menu>
          </Box>
        )}
        {option && (
          <div style={menuContainer}>
            <Button
              variant="outlined"
              onClick={handleClick}
              sx={{
                // backgroundColor:"white", color:mainColor,
                borderColor: "#000",
                color: "#000",
                "&:hover": {
                  borderColor: "#000",
                },
                textTransform: "capitalize",
                width: "250px",
                padding: 1,
              }}
            >
              {" "}
              {option}
            </Button>
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem sx={menuStyle} onClick={handleClose1}>
                1 CAMIcode 29 secs - $3.99
              </MenuItem>
              <MenuItem sx={menuStyle} onClick={handleClose3}>
                1 CAMIcode 3 mins - $6.99
              </MenuItem>
            </Menu>
          </div>
        )}
      </Box>
      {option && (
        <Box sx={getStarted}>
          {" "}
          <Button onClick={handleUploadNavigate} sx={getStartedText}>
            GET STARTED{" "}
          </Button>
        </Box>
      )}
      {!option && (
        <Box sx={getStarted}>
          {" "}
          <Button disabled sx={getStartedText}>
            GET STARTED{" "}
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default CamiSteps;

import React from 'react'
import { Typography } from '@mui/material';
import { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { getFirebaseAuthModule } from '../../Repository/Authentication/Firebase';
import { getUserByEmail, GetFreeCamiCodesListWithPagination, getUserByAuthId } from "../../Repository/Api";
import { CircularProgress, Tooltip } from '@mui/material';
import { baseURL } from '../../Repository/Api';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import { styled } from "@mui/material/styles";
import useDebounce from '../Components/UseDebounceHook';
import { getRecordingTimeFromLS } from "../../Business/MediaBusiness";

const FreeCami = () => {
  // const debouncedSearchTerm = useDebounce(searchInput, 500);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const smallDevices = useMediaQuery(theme.breakpoints.down("md"));
  const [id, setId] = useState("");
  const [currentUserId, setCurrentUserId] = useState("");
  const [filterId, setFilterId] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [sentToInfo, setSentToInfo] = useState("");
  const [pageNumber, setPageNumber] = useState(1)
  const [numberOfPages, setNumberOfPages] = useState(1)
  const [searchInput, setSearchInput] = useState('');
  const [page, setPage] = useState(1)
  const [freeCamiCount, setFreeCamiCount] = useState(0);
  const camiTagImage = baseURL + '/api/CamiCode/GenerateQrCode?camiCode=';
  const navigate = useNavigate();

  const CustomButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#000"),
    backgroundColor: mainColor,
    "&:hover": {
      backgroundColor: "#bf3bbe",
    },
  }));

  const imgStyle = {
    width: 200,
    resize: "cover",
    marginBottom: 15,
    paddingTop: 5,
  }
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "flex-start",
    textDecoration: "none",
    width: { sm: '100%', md: 'inherit' },
    padding: '16px',
    backgroundColor: "#fff",
    textDecoration: "none",
    height: { sm: '100%', md: 'inherit' },
  }
  const mainColor = "#981D97";
  const whiteColor = "#000";

  const fetchData = async (user) => {
    try {
      const getUserDetails = await getUserByAuthId(user.uid)
      setCurrentUserId(getUserDetails?.id);
      setFilterId(getUserDetails?.id);
      setFreeCamiCount(getUserDetails?.freeCamiCodes);
    } catch (error) {
    }
  }

  useEffect(() => {
    const auth = getFirebaseAuthModule();
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((newToken) => {
          localStorage.setItem("JWTTOKEN", newToken);
          fetchData(user);
        });
      } else {
        return ((error => {

        }))
      }
    }
    );
  }, []);
  
  useEffect(requestData, [filterId]);


  function getCreatedDetails() {
    if (currentUserId && id) {
      const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
      const _baseUrl = baseURL + "/api/CamiCode/GetCreatedCamiCodeDet"
      const params = new URLSearchParams({
        userId: currentUserId,
        camiCode: id
      });
      fetch(_baseUrl + '?' + params.toString(), {
        method: "GET",
        mode: "cors",
        headers: {
          'content-Type': 'application/json',
          'Authorization': token
        },
      }).then((res) => res.json()).then((result) => {
        setSentToInfo(result)
      }).catch((error) => {
      });
    }
  }

  useEffect(requestData, [pageNumber, pageSize, filterId]);
  
  useEffect(() => {
    getCreatedDetails()
  }, [currentUserId, id])

  function getRecordingTimeFromQuery() {
    const recordingTime = getRecordingTimeFromLS();
    if (recordingTime) {
      // setRecordingTimer(recordingTime);
    }
  }

  useEffect(() => {
    getRecordingTimeFromQuery();
  })


  function requestData() {
    const auth = getFirebaseAuthModule();
    if (!auth.currentUser) {
      return ((error =>{

      }))
    }
    (async () => {
      setIsLoading(true);
      if (filterId) {
        const data = await GetFreeCamiCodesListWithPagination(pageNumber, pageSize, filterId, searchInput)
        setData(data.list)
        setNumberOfPages(data?.totalPages)
        setIsLoading(false);
        // setSearchInput(data?.searchTerm);
        setPage(data?.pageNumber);
        const _videoDuration =data.list[0]['duration'];
        if (_videoDuration == "29") {
          localStorage.setItem('RT', 'P1');
        } else if (_videoDuration == "180") {
          localStorage.setItem('RT', 'P2');
        }
      } else {
        setTimeout(() => {
          return ("Failed to Fetch Data")
        }, 2500);
      }
    })()
  }


  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
  }


  function handlePaginationChange(event, pageNumber) {
    setPageNumber(pageNumber);
  }

  if (isLoading) {
    return <Box sx={{ width: '100%', textAlign: 'center', paddingTop: '150px', minHeight: "calc(100vh - 250px)" }} ><CircularProgress sx={{ position: 'relative', width: '100%', margin: '0 auto' }} /></Box>
  } else {
    return (
      <Box sx={{ minHeight: "calc(100vh - 250px)" }}>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h5" sx={{ color: mainColor, marginTop: 5, marginBottom: 3 }}>You have {freeCamiCount} free  CAMIcodes left for use!</Typography>
        </Box>
        <>
          <Box sx={{
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: "wrap", width: "100%", padding: 1
          }}>
            {data.map((item, key) => {
              const _camiCode = item['camiCode'] || 'CAMI';
              const isPaid = item['isPaid']
              localStorage.setItem('P', isPaid);
              const _videoDuration =item['duration'];
              if (_videoDuration == 29) {
                localStorage.setItem('RT', 'P1');
                localStorage.removeItem('RT');
              } else if (_videoDuration == 180) {
                localStorage.setItem('RT', 'P2');
                localStorage.removeItem('RT');
              }
              return (
                <Link key={key} underline="none" to={'/upload-video?c=' + _camiCode + '&p=' + item?.uniqueId} style={{ textDecoration: "none", color: mainColor, margin: '10px' }}  >
                  <Paper elevation={2} sx={containerStyle}>
                    <Box sx={{ width: '100%', textAlign: 'center' }} >
                      <img src={camiTagImage + _camiCode} style={imgStyle}></img>
                    </Box>
                    <Box sx={{ color: mainColor }}>
                      <CustomButton variant="contained" sx={{ display: "center", textAlign: "center", justifyContent: "center", marginLeft: 8 }}>USE</CustomButton>
                    </Box>
                  </Paper>
                </Link>
              )
            })}
          </Box>
        </>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", position: "relative", bottom: 5, padding: 4 }}>
          <Pagination count={numberOfPages} page={page} onChange={handlePaginationChange} color="primary" />
        </Box>
      </Box>
    );
  }
}

export default FreeCami
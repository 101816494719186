
//staging
// export let ApiKey ="eMeCh4aYoitxNyY6WHH30TaOV0vuKp4xE6FmN0g5vDT";
//production
// export let ApiKey = "LCaZS5RjQTskYO8KguQStWLhEf6ua8HgYNYdSdr5V3i";


export let getBearerToken = () => {
    return new Promise((resolve, reject) => {
        fetch("https://sandbox.api.video/auth/api-key", {
            method: "POST",
            headers: { 'Content-Type': "application/json" },
            body: JSON.stringify({ "apiKey": "eMeCh4aYoitxNyY6WHH30TaOV0vuKp4xE6FmN0g5vDT" })
        })
            .then(response => response.json())
            .then(response => {
                if (response.access_token) {
                    resolve(response.access_token);
                }
            }).catch((error) => {
                reject(error);
            });
    });
}
export let generateUploadToken = (bearerToken) => {
    return new Promise((resolve, reject) => {
        fetch("https://sandbox.api.video/upload-tokens", {
            method: "POST",
            headers: {
                Accept: "application/json",
                'Content-Type': "application/json",
                Authorization: 'Bearer ' + bearerToken
            },
            body: JSON.stringify({ ttl: 0 })
        })
            .then(response => response.json())
            .then(response => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
    });
}
export let createLiveStreamUrl = (bearerToken, body) => {
    return new Promise((resolve, reject) => {
        fetch("https://sandbox.api.video/live-streams", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + bearerToken
            },
            body: JSON.stringify(body)
        })
            .then(response => response.json(body))
            .then(response => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export let getVideoStatus = (bearerToken, videoId) => {
    return new Promise((resolve, reject) => {
        fetch("https://sandbox.api.video/videos/" + videoId + "/status", {
            method: "GET",
            headers: {
                Accept: "application/json",
                'Content-Type': "application/json",
                Authorization: "Bearer " + bearerToken
            }
        })
            .then(response => response.json())
            .then(response => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
const Exports = {
    getBearerToken,
    createLiveStreamUrl,
    generateUploadToken,
    getVideoStatus
}
export default Exports;
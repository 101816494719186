import React, { useEffect, useState, useRef } from 'react';
import { ElementsConsumer, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Box, Button, CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel, InputBase, Paper, Radio, RadioGroup, Typography, useTheme } from '@mui/material';
import Login from '../Pages/Login';
import Register from '../Pages/Register';
import ManageAuthModule from '../Components/ManageAuthModule';
import { getCurrentSignedInUser, getFirebaseAuthModule } from '../../Repository/Authentication/Firebase';
import { validateEmail, validateName } from '../../Business/AuthenticationBusiness';
import { TextField } from '@mui/material';
import { styled } from "@mui/material/styles";
import { getUserByEmail, createTransaction, getUserByAuthId, getCardDetails, baseURL } from '../../Repository/Api';
import Checkbox from '@mui/material/Checkbox';

const CheckoutForm = ({ paymentStatus, paid, card, itemId, secret,camiTypeId }) => {
    const stripe = useStripe();
    const elements = useElements();
    let CSIU;
    const FormRef = useRef();
    let [loginState, setLoginState] = useState(false);
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    let [videoName, setVideoName] = useState('');
    let [videoNameError, setVideoNameError] = useState('');
    let [videoEmail, setVideoEmail] = useState('');
    let [subject, setSubject] = useState('');
    let [videoEmailError, setVideoEmailError] = useState('');
    let [loginType, setLoginType] = useState('guest');
    const [apiData, setApiData] = useState([]);
    const [tId, setTId] = useState("");
    const [tDate, setTDate] = useState("");
    const [tStatus, setTStatus] = useState(false);
    const [tAmount, setTAmount] = useState("");
    const [data, setData] = useState({})
    const [transactionId, setTransactionId] = useState("");
    const [transactionDate, setTransactionDate] = useState("");
    const [transactionStatus, setTransactionStatus] = useState(false);
    const [transactionAmount, setTransactionAmount] = useState("");
    const [userId, setUserId] = useState("");
    const [currentEmail, setCurrentEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [checked, setChecked] = useState(true);
    const [changeCard, setChangeCard] = useState(true);
    const [showCard, setShowCard] = useState(false);
    const [save, setSave] = useState(false);
    const [disableSave, setDisableSave] = useState(false);
    const [notLoggedIn, setNotLoggedIn] = useState(false);
    const [cardPresent, setCardPresent] = useState(false);
    const [hide, setHide] = useState(false);

    const theme = useTheme();
    const CustomButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText("#000"),
        backgroundColor: mainColor,
        "&:hover": {
            backgroundColor: "#bf3bbe",
        },
    }));
    const mainColor = "#981D97";
    const inputFieldColor = "#ecedee";
    const errorText = {
        color: "red",
        fontSize: 13,
        marginTop: 1,
        marginBottom: 1,
    };

    const titleAndDescStyle = {
        backgroundColor: inputFieldColor,
        paddingBlock: 1,
        paddingInlineStart: 1,
        paddingInlineEnd: 1,
        // borderRadius: 0,
        mb: 1,
    }

    useEffect(() => {
        if (paid == false) {
            if (card == 'false') {
                //if card exist...
                setShowCard(false);
            } else {
                //if no card
                setShowCard(true);
            }
        }
        // if (!firstName && !currentEmail){
        //     setDisableSave(true)
        // }
    }, [card])

    useEffect(() => {
        CSIU = getCurrentSignedInUser().subscribe((event) => {
            if (event.state === "logged in") {
                setLoginState(true);
            } else if (event.state === "logged out") {
                setLoginState(false);
            }
        });
        return () => {
            CSIU.unsubscribe();
        }
    }, []);


    useEffect(() => {
        if (!stripe) {
            return;
        }
        const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
        if (!clientSecret) return;
        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case 'succeeded':
                    setMessage('Payment succeeded!');
                    break;
                case 'processing':
                    setMessage('Your payment is processing.');
                    break;
                case 'requires_payment_method':
                    setMessage('Your payment was not successful, please try again.');
                    break;
                default:
                    setMessage('something went wrong.');
                    break;
            }
        });
    }, [stripe]);

    useEffect(() => {
        const auth = getFirebaseAuthModule();
        auth.onAuthStateChanged((user) => {
            if (user) {
                fetchData(user.uid);
            } else {
                // fetchData(user.email);
                setNotLoggedIn(true);
               
            }

        }
        );
    }, []);

    const fetchData = async (user) => {
        const res = await getUserByAuthId(user)
            .then(response => {
                setApiData(response);
                setUserId(response.id);
                setCurrentEmail(response.email);
                setFirstName(response.firstName);
                setVideoEmail(response.email);
                setVideoName(response.firstName);
            }).catch(error => {

            })
    }

    const handleSubmit = async (event) => {
       
        event.preventDefault();
        if (paid == true) {
            

            if (!stripe || !elements) {
                return;
            }
            const _email = validateEmail(videoEmail);
            const _name = validateName(videoName);
            if (!_email) {
                setVideoEmailError("Please enter a valid email");
            } else {
                setVideoEmailError("");
            }
            if (!_name) {
                setVideoNameError("Please enter a valid name");
            } else {
                setVideoNameError("");
            }

            if (!_name || !_email) {
               
                return;
            }
         
            setHide(true);
            paymentStatus({ 'paymentIntent': {}, 'paymentDetails': { 'subject': subject, 'videoEmail': videoEmail, 'videoName': videoName } });
            setMessage("Payment successful");
            //  return;

        } else if (paid == false) {
            if (!stripe || !elements) {
                return;
            }
            const _email = validateEmail(videoEmail);
            const _name = validateName(videoName);
            if (!_email) {
                setVideoEmailError("Please enter a valid email");
            } else {
                setVideoEmailError("");
            }
            if (!_name) {
                setVideoNameError("Please enter a valid name");
            } else {
                setVideoNameError("");
            }

            if (!_name || !_email) {
                
                return;
            }
            setMessage("");

            setIsLoading(true); 
            setHide(true);
            const result = await stripe.confirmPayment({
                elements,
                redirect: 'if_required',
                confirmParams: {
                    return_url: window.location.href
                }
            });
            var _paymentIntent = result['paymentIntent'] || {};
            if (_paymentIntent['status'] === "succeeded") {
                setTransactionAmount(_paymentIntent['amount'])
                setTransactionId(_paymentIntent['id'])
                setTransactionStatus(_paymentIntent['status'])
                paymentStatus({ 'paymentIntent': _paymentIntent, 'paymentDetails': { 'subject': subject, 'videoEmail': videoEmail, 'videoName': videoName } });
                setMessage("Payment successful");
            }
            if (result.error.type === 'card_error' || result.error.type === 'validation_error') {
                setMessage(result.error.message);
                // show error to your customer
            } else {
                setMessage('An unexpected error occured.');
                // customers will be redorected to the specified url
            }

        }
    };

    const handleLoginOptions = (event, value) => {
        setLoginType(value);
        setNotLoggedIn(false)
    };

    useEffect(() => {
        if (userId && transactionStatus && transactionAmount && transactionId) {
            createTransactionDetail();
        }
    }, [transactionStatus]);

    const createTransactionDetail = (e) => {
        if (transactionId && userId && transactionStatus && transactionAmount) {
            const _transactionDetail = {
                "transactionId": transactionId,
                "transactionStatus": transactionStatus,
                "userId": parseInt(userId),
                "transactionAmount": parseInt(transactionAmount)
            }
            createTransaction(_transactionDetail)
                .then((response) => {

                }).catch((error) => {
                })
        }
    }

    useEffect(() => {
        if (save == true) {
            const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
            const updateIntentUrl = baseURL + "/api/Stripe/update-payment-intent?createdPI=" + (secret) + "&sfu=" + (save);
            fetch(updateIntentUrl, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    items: {
                        id: itemId,
                        name: firstName,
                        email: currentEmail
                    }
                })
            }).then((res) => res.json())
                .then((data) => {

                }).catch((error) => {
                });
        } else if (save == false) {
            const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
            const updateIntentUrl = baseURL + "/api/Stripe/update-payment-intent?createdPI=" + (secret) + "&sfu=" + (save);
            fetch(updateIntentUrl, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    items: {
                        id: itemId,
                        name: firstName,
                        email: currentEmail
                    }
                })
            }).then((res) => res.json())
                .then((data) => {

                }).catch((error) => {
                });
        }
    }, [save])

    const UserCard = () => {
        if (isLoading) {
            return <Box sx={{ width: '100%', textAlign: 'center', paddingTop: '150px' }} ><CircularProgress sx={{ position: 'relative', width: '100%', margin: '0 auto' }} /></Box>

        } else {
            return (
                <Box sx={{ mb: 3, alignItems: "flex-start" }}>
                    {/* <h4 style={{ color: mainColor, display: "flex", textAlign: "center", marginTop: "20px" }}> Use Your Card? </h4> */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            testAlign: "center",
                            justifyContent: "center",
                            color: mainColor,
                            '& > :not(style)': {
                                m: 1,
                                width: 180,
                                height: 110,
                            },
                        }}
                    >
                        <Paper style={{ backgroundImage: "linear-gradient(#981D97 ,#BF9BDE)" }} >
                            <Box sx={{ display: "flex", justifyContent: "space-evenly", marginTop: 3 }}>
                                <Typography sx={{ fontSize: 14, color: "#FFFFFF", fontWeight: "bold", fontFamily: "cursive" }} >
                                    CAMIcode
                                </Typography>
                                <Typography sx={{ fontSize: 14, color: "#FFFFFF", fontWeight: "bold" }} >
                                    {card.brand}
                                </Typography>
                            </Box>
                            <Typography sx={{ fontSize: 10, color: "#FFFFFF", fontWeight: "bold", display: "flex", justifyContent: "center", textAlign: "center", marginTop: 2 }} >
                                XXXX XXXX XXXX {card.number}
                            </Typography>
                            <Typography sx={{ fontSize: 10, color: "#FFFFFF", fontWeight: "bold", display: "flex", marginLeft: 15, marginTop: 1 }} >
                                {card.expMonth}/{card.expYear}
                            </Typography>
                        </Paper>
                    </Box>
                </Box>
            )
        }
    }

    // const criteria = isLoading || !stripe || !elements || (loginState === false && (loginType == 'login' || loginType == 'create'));
    const criteria = (loginState === false && (loginType == 'login' || loginType == 'create') );

    const handleCreatePaymentIntentExisting = (e) => {
        setHide(true);
        // instant payment for Existing customer
        e.preventDefault();
        e.stopPropagation();
    }
    const handlePayUsingCard = () => {
        setHide(true);
        // createPaymentIntentExisting()
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        fetch(baseURL + "/api/stripe/create-payment-intent-existing", {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                items: {
                    id: itemId,
                    name: firstName,
                    email: currentEmail
                }
            })
        }).then((res) => res.json())
            .then((data) => {
                // setClientSecret(data.clientSecret)
                var _paymentIntent = data || {};
                if (_paymentIntent['status'] === "succeeded") {
                    setTransactionAmount(_paymentIntent['amount'])
                    setTransactionId(_paymentIntent['id'])
                    setTransactionStatus(_paymentIntent['status'])
                    paymentStatus({ 'paymentIntent': _paymentIntent, 'paymentDetails': { 'subject': subject, 'videoEmail': videoEmail, 'videoName': videoName } });
                    setMessage("Payment successful");
                }
                // setData(result)
                if (data.error) {
                    if (data.error.type === 'card_error' || data.error.type === 'validation_error') {
                        setMessage(data.error.message);
                        // show error to your customer
                    } else {
                        setMessage('An unexpected error occured.');
                        // customers will be redorected to the specified url
                    }
                }
            }).catch((error) => {

            });
    }
    const handlePayOldStyle = () => {
        setShowCard(!showCard)
        setCardPresent(true);
    }

    return (<>
        {loginState === false && <FormControl>
            <FormLabel id="user-session-options" >Login to an existing account or create one instead?</FormLabel>
            <RadioGroup onChange={handleLoginOptions} defaultValue="guest" name="user-session-options" >
                <FormControlLabel value="create" control={<Radio />} label="Create Account" />
                <FormControlLabel value="login" control={<Radio />} label="Sign In" />
                <FormControlLabel value="guest" control={<Radio />} label="Continue as Guest" />
            </RadioGroup>
        </FormControl>}
        {/* <ManageAuthModule /> */}
        {loginState === false && loginType == 'login' && <Login type='imported' />}
        {loginState === false && loginType == 'create' && <Register type='imported' />}
        <form ref={FormRef} onSubmit={(showCard == true) ? handleCreatePaymentIntentExisting : handleSubmit}>
            <Box sx={{ marginBottom: 1, display: "flex", flexDirection: "column" }}>
                <InputBase
                    size="small"
                    id="VideoName"
                    placeholder="Your Name"
                    name="VideoName"
                    autoFocus
                    onChange={(e) => { setVideoName(e.target.value); }}
                    value={videoName}
                    sx={titleAndDescStyle}
                    variant="outlined"
                />
                {/* {formErrors.email && (<p >{formErrors.email}</p>)} */}

                {videoNameError && <p style={errorText}> {videoNameError} </p>}
                <InputBase
                    size="small"
                    id="EmailVideo"
                    placeholder="Your eMail"
                    name="EmailVideo"
                    onChange={(e) => { setVideoEmail(e.target.value) }}
                    value={videoEmail}
                    sx={titleAndDescStyle}
                    variant="outlined"
                />
                {videoEmailError && <p style={errorText}> {videoEmailError} </p>}
                <InputBase
                    size="small"
                    id="Subject"
                    placeholder= {camiTypeId == 5?"Name Your CAMIforever":"Name your camicode"}
                    name="Subject"
                    onChange={(e) => { setSubject(e.target.value) }}
                    value={subject}
                    sx={titleAndDescStyle}
                    variant="outlined"
                />
            </Box>
            {/* {if doesnt have false, i.e, if there is a card, display card} */}
            {(showCard == true) && paid == false && UserCard()}
            {/* <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: 1, marginBottom: 1 }}>
            </Box> */}
            {card != 'false' && paid == false && <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly", padding: 1, marginBottom: 1 }}>
                {showCard == true && <CustomButton onClick={handlePayUsingCard} disabled={hide}
                >
                    {"PAY"}
                    {/* {paid == true && "SAVE"} */}
                </CustomButton>}
                <CustomButton sx={{}} onClick={handlePayOldStyle}
                >
                    {"CHANGE CARD"}
                    {/* {paid == true && "SAVE"} */}
                </CustomButton>
            </Box>}
            {(paid == false) && showCard == false && <>
                <PaymentElement id="payment-element" />
            </>}
            {showCard == false && paid == false && notLoggedIn == false && cardPresent == false && <FormGroup>
                <FormControlLabel control={<Checkbox />} label={save == false ? "Save Card" : "Uncheck to disable save card"} value={save} onChange={() => (setSave(!save))} />
            </FormGroup>}
            {/* {(card != false && notLoggedIn == false && showCard == false && paid == false ) && <FormGroup>
                <FormControlLabel control={<Checkbox />} label={save == false ? "Save Card" : "Uncheck to disable save card"} value={save} onChange={() => (setSave(!save))} />
            </FormGroup>} */}
                {notLoggedIn == true && <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: 1, marginBottom: 1 }}>
                    <CustomButton id="submit" disabled={criteria || hide} 
                        type='submit' >
                        {"SAVE"}
                    </CustomButton>
                </Box>}
                {notLoggedIn == false && paid == false && showCard == false && <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: 1, marginBottom: 1 }}>
                    <CustomButton id="submit" disabled={criteria || hide}
                        type='submit' >
                        {"SAVE & PAY"}
                    </CustomButton>
                </Box>}
                {notLoggedIn == false && paid == true && <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: 1, marginBottom: 1 }}>
                    <CustomButton id="submit" disabled={criteria || hide}
                        type='submit' >
                        {"SAVE"}
                    </CustomButton>
                </Box>}

            {message && <div style={errorText} id="payment-message">{message}</div>}
        </form>
    </>
    )
};

export default CheckoutForm;
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


const RecordTimer = ({ stopRecordingCallback, recordTimerProgress }, ref) => {
    let [pauseTimer, setPauseTimer] = useState(false);
    let [timer, setTimer] = useState(null);
    let [manualStop, setManualStop] = useState(false);
    let [theTime, setTheTime] = useState("")
    let[recordCount,setRecordCount] = useState(0);
  

    const theme = useTheme();
    const smallDevices = useMediaQuery(theme.breakpoints.down("md"));

    useImperativeHandle(ref, () => ({
        startTimer(timerInSeconds) {
            if (typeof timerInSeconds === 'undefined') timerInSeconds = 29000;
            setManualStop(false);
            setTimer(timerInSeconds / 1000);
            
        },
        resumeTimer() {
            setPauseTimer(false);
        },
        pauseTimer() {
            setPauseTimer(true);
        },
        stopTimer() {
            setManualStop(true);
        },
        stopRecording() {
            setManualStop(true);
        }
    }), []);
    
      
        
       
    const stopRecording_Timeout = () => {
        if (manualStop !== true) {
            stopRecordingCallback();
        }
    }

    const setTimerCountdown = () => {
        if (manualStop !== true) {
            recordTimerProgress(Number(timer) - 1);
            setTimer(Number(timer) - 1);
            setRecordCount(Number(recordCount) + 1);
        }
    }
    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }
    const timerEffect = () => {
        const minute = Math.floor(timer / 60)
        const seconds = timer % 60;
        var padMinutes = padTo2Digits(minute);
        var padSeconds = padTo2Digits(seconds);
        if (padMinutes == 0) {
            const displayTime = (padSeconds)
            setTheTime(displayTime)
        }
        else {
            const displayTime = (padMinutes + " : " + padSeconds);
            setTheTime(displayTime);
        }






        if (timer !== null) {
            if (timer > 0 && pauseTimer === false && manualStop === false ) {
                setTimeout(setTimerCountdown.bind(this), 1000);
            } else if (timer === 0 ) {
                stopRecording_Timeout();
            }
        }
      
           
    }

    // useEffect(()=>{

    // },[])

    useEffect(() => {
        if (pauseTimer === false) {
            timerEffect();
        }
    }, [pauseTimer]);

    useEffect(() => {
        if (manualStop === true) {
            setTimer(0);
        }
    }, [manualStop]);

    useEffect(timerEffect, [timer]);

    const VideoPreviewStyleSM = {
        position: 'absolute',
        top: "80px",
        right: "30px",
        color: "white",
        backgroundColor: "#981D97",
        padding: "10px",
        borderRadius: "100%",
        fontWeight: "700",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center"
    };
    const VideoPreviewStyleMD = {
        position: 'absolute',
        top: "80px",
        left: 'calc(70vw - 55px)',
        color: "white",
        backgroundColor: "#981D97",
        padding: "15px",
        fontSize: "19px",
        borderRadius: "100%",
        fontWeight: "700",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center"
    };
    let videoPreviewStyle = VideoPreviewStyleMD;
    if (smallDevices) {
        videoPreviewStyle = VideoPreviewStyleSM;
    } else {
        videoPreviewStyle = VideoPreviewStyleMD;
    }

    if ((timer) > 0 && manualStop === false && smallDevices) {
        return (<div style={videoPreviewStyle} > {theTime} </div>)
    } else if (timer > 0 && manualStop === false && !smallDevices) {
        return (<div style={videoPreviewStyle} >{theTime}</div>)
    }
}

export default forwardRef(RecordTimer);

import React from 'react';
import { Routes, Route, Link } from "react-router-dom";





function AboutCami() {
 
  return (
    <>
      <main style={{ minHeight: "calc(100vh - 250px)"  }}>
        <p>
          You're now inside <em>About CAMI</em>
        </p>
      
      </main>
     
    </>
  );
}

export default AboutCami

import AppHeader from "../Components/AppHeader";
import React, { useEffect } from "react";
import { Outlet } from 'react-router-dom';
import MobileFooterNoAuth from "../Components/mobileFooterNoAuth";
import { getFirebaseAuthModule } from "../../Repository/Authentication/Firebase";
import { useLocation } from "react-router-dom";
import Footer from "../Components/Footer";


export default function Open() {
// const currentLocation = useLocation();
// const loginRegister = ['/login', '/register']
    useEffect(() => {
            const auth = getFirebaseAuthModule();
            auth.onAuthStateChanged((user) => {
                if (user) {
                    user.getIdToken().then((newToken) => {
                        localStorage.setItem("JWTTOKEN", newToken);
                       
                    });
                } else {
                    return ((error => {}))
                }
            }
            );
        // get the current location using useLocation
        // if the requested page is one amoung login or registration,
        // check whether user is logged in using the oauthstatechanged
        // if user the logged in, then redirect to the /account page
        // if the requested route is not among the login or registration, then do not unterrupt the request.
    }, []);
    return (
        <section>
            <AppHeader />
            <div style={{ minHeight: 'calc(100vh - 225px)' }} >
                <Outlet />
                </div>
            <Footer />
        </section>
    );
}
import React from "react";
import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { getFirebaseAuthModule } from "../../Repository/Authentication/Firebase";
import {
  getUserByEmail,
  GetFreeCamiCodesListWithPagination,
  getUserByAuthId,
  GetCamiForeverCodesListWithPagination,
} from "../../Repository/Api";
import { CircularProgress, Tooltip } from "@mui/material";
import { baseURL } from "../../Repository/Api";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import useDebounce from "../Components/UseDebounceHook";
import { getRecordingTimeFromLS } from "../../Business/MediaBusiness";
import DownloadIcon from "@mui/icons-material/Download";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import IconButton from "@mui/material/IconButton";
import {
  Divider,
  InputBase,
  ListItemIcon,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import UploadIcon from "@mui/icons-material/Upload";
import { downloadFile } from "../../Business/MediaBusiness";
import AppPagination from "../Components/Pagination/AppPagination";
import usePagination from "../Components/Pagination";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { SendToForeverUser } from "../../Repository/Api";
import {
  validateEmail,
  validateName,
} from "../../Business/AuthenticationBusiness";
import MuiAlert from "@mui/material/Alert";

const ForeverCamiCodes = () => {
  // const debouncedSearchTerm = useDebounce(searchInput, 500);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const smallDevices = useMediaQuery(theme.breakpoints.down("md"));
  const [id, setId] = useState("");
  const [currentUserId, setCurrentUserId] = useState("");
  const [filterId, setFilterId] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [sentToInfo, setSentToInfo] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [freeCamiCount, setFreeCamiCount] = useState(0);
  const camiTagImage = baseURL + "/api/CamiCode/SvgforwebCustomer?cami=";
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [email, setEmail] = useState("");
  const [videoId, setVideoId] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dataToShare, setDataToShare] = useState("");
  const [formToEmail, setFormToEmail] = useState("");
  const [videoEmailError, setVideoEmailError] = useState("");
  const [encodeId, setEncodeId] = useState("");
  const [isMessage, setIsMessage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const errorText = {
    color: "red",
    fontSize: 13,
    marginTop: 1,
    marginBottom: 1,
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const CustomButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#000"),
    backgroundColor: mainColor,
    margin: "20px",
    "&:hover": {
      backgroundColor: "#bf3bbe",
    },
  }));

  const imgStyle = {
    width: 250,
    resize: "cover",
    marginBottom: 15,
    paddingTop: 5,
  };
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "flex-start",
    textDecoration: "none",
    // width: { sm: '100%', md: 'fitContent' },
    padding: "16px",
    backgroundColor: "#fff",
    textDecoration: "none",
    height: { sm: "100%", md: "fitContent" },
    margin: "15px",
    // height: { sm: '100%', md: 'fitContent' },
  };

  const descriptionStyle = {
    fontSize: 10,
    textDecoration: "none",
    fontWeight: "bold",
    minHeight: "20px",
  };
  const mainColor = "#981D97";
  const whiteColor = "#000";

  const fetchData = async (user) => {
    try {
      const getUserDetails = await getUserByAuthId(user.uid);
      setCurrentUserId(getUserDetails?.id);
      setFilterId(getUserDetails?.id);
      setEmail(getUserDetails.email);
      setFreeCamiCount(getUserDetails?.foreverCamiCodes);
    } catch (error) {
    }
  };

  const CustomIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.getContrastText(whiteColor),
    padding: "5px",
    margin: "15px",
    backgroundColor: mainColor,
    "&:hover": {
      backgroundColor: "#bf3bbe",
    },
    "&:disabled": {
      backgroundColor: "grey",
    },
  }));

  const background = {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  };

  useEffect(() => {
    const auth = getFirebaseAuthModule();
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((newToken) => {
          localStorage.setItem("JWTTOKEN", newToken);
          fetchData(user);
        });
      } else {
        return (error) => {

        };
      }
    });
  }, []);

  useEffect(requestData, [filterId]);

  const handleClickOpenModal = (forever) => {
    setEncodeId(forever);
    setOpenModal(true);
  };
  function handleCancelModal() {
    setOpenModal(false);
    setFormToEmail("");
    setVideoEmailError("");
  }

  function handleCloseModal() {
    setOpenModal(false);
    handleSend();
    //clearStorage();
  }

  const handleSend = () => {
    // e.preventDefault();
    networkCall();
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
    window.location.reload();
  };

  const handleAlertClose = () => {
    setOpenAlert(true);
  };

  const alertDialog = () => {
    if (openAlert === true) {
      return (
        <Dialog onClose={handleAlertClose} open={openAlert}>
          <DialogContent>
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "150px",
                  maxWidth: "100%",
                }}
              >
                <Alert
                  onClose={handleCloseAlert}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Re-assigned Successfully!
                </Alert>
              </Box>
            </>
          </DialogContent>
        </Dialog>
      );
    }
  };

  const dialog = () => {
    return (
      <Box>
        <Dialog open={openModal} onClose={handleClickOpenModal}>
          <DialogTitle sx={{ paddingBottom: 0 }}>
            Re-assign the Forevercode
          </DialogTitle>
          <DialogContent>
            <>
              <form style={{ width: "275px" }}>
                <InputBase
                  margin="dense"
                  id="email"
                  placeholder="Enter the eMail address"
                  type="email"
                  fullWidth
                  variant="standard"
                  value={formToEmail}
                  onChange={(event) => {
                    setFormToEmail(event.target.value);
                  }}
                  required
                  sx={{
                    backgroundColor: "#ecedee",
                    marginTop: 2,
                    padding: 1,
                  }}
                />
                {videoEmailError && (
                  <p style={errorText}> {videoEmailError} </p>
                )}
                <Box sx={{ textAlign: "center", marginTop: 3 }}>
                  <CustomButton
                    sx={{ marginRight: 1 }}
                    onClick={() => validate()}
                  >
                    Send
                  </CustomButton>
                  <CustomButton
                    sx={{ marginLeft: 1 }}
                    onClick={() => handleCancelModal()}
                  >
                    Cancel
                  </CustomButton>
                </Box>
              </form>
            </>
          </DialogContent>
          <DialogActions sx={{ alignSelf: "center" }}></DialogActions>
        </Dialog>
      </Box>
    );
  };

  function getCreatedDetails() {
    if (currentUserId && id) {
      const token = "Bearer " + localStorage.getItem("JWTTOKEN");
      const _baseUrl = baseURL + "/api/CamiCode/GetCreatedCamiCodeDet";
      const params = new URLSearchParams({
        userId: currentUserId,
        camiCode: id,
      });
      fetch(_baseUrl + "?" + params.toString(), {
        method: "GET",
        mode: "cors",
        headers: {
          "content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          setSentToInfo(result);
        })
        .catch((error) => {
        });
    }
  }

  useEffect(requestData, [pageNumber, pageSize, filterId]);

  useEffect(() => {
    getCreatedDetails();
  }, [currentUserId, id]);

  function getRecordingTimeFromQuery() {
    const recordingTime = getRecordingTimeFromLS();
    if (recordingTime) {
      // setRecordingTimer(recordingTime);
    }
  }

  useEffect(() => {
    getRecordingTimeFromQuery();
  });

  function requestData() {
    const auth = getFirebaseAuthModule();
    if (!auth.currentUser) {
      return (error) => {

      };
    }
    (async () => {
      setIsLoading(true);
      if (filterId) {
        const data = await GetCamiForeverCodesListWithPagination(
          pageNumber,
          pageSize,
          filterId,
          searchInput,
          email
        );
        setData(data.list);
        setNumberOfPages(data?.totalPages);
        setIsLoading(false);
        setSearchInput(data?.searchTerm);
        setPage(data?.pageNumber);
        const _videoDuration = data.list[0]["duration"];
        if (_videoDuration == "29") {
          localStorage.setItem("RT", "P1");
        } else if (_videoDuration == "180") {
          localStorage.setItem("RT", "P2");
        }
      } else {
        setTimeout(() => {
          return "Failed to Fetch Data";
        }, 2500);
      }
    })();
  }

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
  };
  function handlePaginationChange(event, pageNumber) {
    setPageNumber(pageNumber);
  }
  function Download(video) {
    const downloadVideoURL = video
      ? "https://cdn.api.video/vod/" + video + "/mp4/source.mp4"
      : data["fileUrl"];
    downloadFile(downloadVideoURL);
  }

  function DownloadDropDown(videoId) {
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <>
        <Tooltip title="Select download option">
          <Button
            sx={{
              color: theme.palette.getContrastText(whiteColor),
              backgroundColor: mainColor,
              borderRadius: "50%",
              minWidth: "38px",
              margin: "20px",
              padding: "5px",
              "&:hover": {
                backgroundColor: "#bf3bbe",
              },
              "&:disabled": {
                backgroundColor: "grey",
              },
            }}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <DownloadIcon />
          </Button>
        </Tooltip>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {/* <MenuItem ><a style={{ textDecoration: "none" }} target="_blank">CAMIcode</a></MenuItem> */}
          <MenuItem onClick={() => Download(videoId)}>Download Video</MenuItem>
        </Menu>
      </>
    );
  }

  function UploadVideo(camiCode, password) {
    navigate("/upload-video?c=" + camiCode + "&p=" + password);
  }

  function handleWatch(camicode, password) {
    const watchUrl =
      "/watch?c=" +
      camicode +
      "&p=" +
      password.toString().replace(/ /g, "") +
      "&redir=" +
      encodeURIComponent("/camicard");
    window.open(watchUrl, "_blank");
  }

  const dialogButton = {
    display: "block",
    margin: "10px auto",
    width: "100%",
    borderRadius: 20,
    fontSize: "12px",
    width: "100%",
  };

  function handledialogopen() {
    setOpenAlert(true);
  }
  function handleDialogClose() {
    setOpenAlert(false);
  }

  if (isLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          paddingTop: "150px",
          minHeight: "calc(100vh - 250px)",
        }}
      >
        <CircularProgress
          sx={{ position: "relative", width: "100%", margin: "0 auto" }}
        />
      </Box>
    );
  } else {
    return (
      <Box sx={{ minHeight: "calc(100vh - 250px)" }}>
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h5"
            sx={{ color: mainColor, marginTop: 5, marginBottom: 3 }}
          >
            CAMIforever Code
          </Typography>
        </Box>
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              width: "100%",
              padding: 1,
            }}
          >
            {data.map((item, key) => {
              const _camiCode = item["camiCode"] || "CAMI";
              const _uniqueId = item["uniqueId"];
              const isPaid = item["isPaid"];
              const videoId = item["videoId"];
              const forever = item["forever"];
              localStorage.setItem("P", isPaid);
              const _videoDuration = item["duration"];
              if (_videoDuration == 29) {
                localStorage.setItem("RT", "P1");
                localStorage.removeItem("RT");
              } else if (_videoDuration == 180) {
                localStorage.setItem("RT", "P2");
                localStorage.removeItem("RT");
              }
              return (
                <Paper elevation={2} sx={containerStyle}>
                  <Box sx={{ width: "100%", textAlign: "center" }}>
                    <img src={camiTagImage + _camiCode} style={imgStyle}></img>
                  </Box>
                  <Box
                    sx={{
                      marginLeft: 1,
                      width: "100%",
                      marginBottom: 1,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography variant="p" fontSize={10} sx={descriptionStyle}>
                      {item?.subject ? item?.subject : ""}
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex" }}>
                    {/* {DownloadDropDown(videoId)} */}
                    {videoId == null && (
                      <Tooltip
                        TransitionProps={{ timeout: 600 }}
                        title="Download video"
                      >
                        <span>
                          <CustomIconButton
                            disabled
                            onClick={() => Download(videoId)}
                          >
                            <DownloadIcon />
                          </CustomIconButton>
                        </span>
                      </Tooltip>
                    )}
                    {videoId != null && (
                      <Tooltip
                        TransitionProps={{ timeout: 600 }}
                        title="Download video"
                      >
                        <span>
                          <CustomIconButton onClick={() => Download(videoId)}>
                            <DownloadIcon />
                          </CustomIconButton>
                        </span>
                      </Tooltip>
                    )}
                    <Tooltip
                      TransitionProps={{ timeout: 600 }}
                      title="Upload Video"
                    >
                      <CustomIconButton
                        onClick={() => UploadVideo(_camiCode, _uniqueId)}
                      >
                        <UploadIcon />
                      </CustomIconButton>
                    </Tooltip>
                    {videoId == null && (
                      <Tooltip
                        TransitionProps={{ timeout: 600 }}
                        title="Play Video or Preview Image/PDF"
                      >
                        <span>
                          <CustomIconButton
                            disabled
                            onClick={() => handleWatch(_camiCode, _uniqueId)}
                          >
                            <PlayArrowIcon />
                          </CustomIconButton>
                        </span>
                      </Tooltip>
                    )}
                    {videoId != null && (
                      <Tooltip
                        TransitionProps={{ timeout: 600 }}
                        title="Play Video or Preview Image/PDF"
                      >
                        <CustomIconButton
                          onClick={() => handleWatch(_camiCode, _uniqueId)}
                        >
                          <PlayArrowIcon />
                        </CustomIconButton>
                      </Tooltip>
                    )}

                    <Tooltip
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                      title="Remap your CAMIcode"
                    >
                      <CustomIconButton
                        variant="contained"
                        // sx={printCodeStyle}
                        onClick={() => {
                          handleClickOpenModal(forever);
                        }}
                      >
                        <AccountCircleIcon />
                      </CustomIconButton>
                    </Tooltip>
                  </Box>
                </Paper>
              );
            })}
          </Box>
        </>
        {dialog()};{alertDialog()}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            bottom: 5,
            padding: 4,
          }}
        >
          <Pagination
            count={numberOfPages}
            page={page}
            onChange={handlePaginationChange}
            color="primary"
          />
        </Box>
      </Box>
    );
  }

  function networkCall() {
    if (formToEmail) {
      const _payload = {
        encodeId: encodeId,
        email: formToEmail,
      };
      // localStorage.clear();
      setDataToShare(_payload);
      SendToForeverUser(_payload)
        .then((res) => {
          if (res === "") {
            alert("Success");
          }
          setOpenAlert(true);
          setFormToEmail("");
        })
        .catch((error) => {
          setFormToEmail("");
          // display error message.
        });
      setOpenAlert(true);
    }
  }

  function validate() {
    const _email = validateEmail(formToEmail);
    if (!_email) {
      setVideoEmailError("Please enter a valid email");
    } else {
      setVideoEmailError("");
    }
    // if (!_name) {
    //     setVideoNameError("Please enter a valid name");
    // } else {
    //     setVideoNameError("");
    // }
    if (!_email) {
      // alert('coming inside');
      return;
    } else return handleCloseModal();
  }
};
export default ForeverCamiCodes;

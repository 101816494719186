import React, { useEffect, useRef, useState } from "react";
import { Html5Qrcode, Html5QrcodeSupportedFormats } from "html5-qrcode";
import { validateCamicode } from "../../Business/CamicodeBusiness";
import {
  Alert,
  AlertTitle,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { connectStorageEmulator } from "firebase/storage";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { baseURL, createLogDetails, findlocationmapping } from "../../Repository/Api";
import { getFirebaseAuthModule } from "../../Repository/Authentication/Firebase";
import { getUserByAuthId } from "../../Repository/Api";
import { Link } from "react-router-dom";
import { startTransition } from "react";

const ScanCamiCode = () => {
  let [result, setResult] = useState("");
  let [feedback, setFeedback] = useState({});
  let [uniqIdFromResult, setUniqIdFromResult] = useState("");
  let [redirect, setRedirect] = useState("");
  const [background, setBackground] = useState({ backgroundColor: "#981D97" });
  const readerRef = useRef();
  const navigate = useNavigate();
  const theme = useTheme();
  const smallDevices = useMediaQuery(theme.breakpoints.down("md"));
  const [cameraState, setCameraState] = useState(false);
  const [decodeResponse, setDecodeResponse] = useState("");
  const [password, setPassword] = useState("");
  const [camiCode, setCamiCode] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [imageType, setImageType] = useState("");
  const [user, setUser] = useState("");
  const [foreverCami, setForeverCami] = useState(0);
  const [freeCami, setFreeCami] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [loginLink, setLoginLink] = useState(false);
  const [CameraError, setCameraError] = useState("");
  const [scannerEnabled, setscannerEnabled] = useState(true);
  const isFirstRender = useRef(true);
  useEffect(startScanning, []);

  const logDate = {
    level: 'info',
    message: 'Scan Page is visited'
  }

  useEffect(() => {
    const auth = getFirebaseAuthModule();
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((newToken) => {
          localStorage.setItem("JWTTOKEN", newToken);
          setUser(user);
          fetchData(user);
          fetchFreeCamiData(user);
        });
      } else {
        return (error) => { };
      }
    });
  }, []);

  const fetchData = async (user) => {
    try {
      const getUserDetails = await getUserByAuthId(user.uid);
      let foreverCode = getUserDetails.foreverCamiCodes;
      setForeverCami(foreverCode);
    } catch (error) { }
  };


  const fetchFreeCamiData = async (user) => {
    try {
      const getUserDetails = await getUserByAuthId(user.uid)
      let freeCode = getUserDetails.freeCamiCodes;
      console.log(freeCode);
      setFreeCami(freeCode);
      console.log(freeCode)
    } catch (error) {
      console.log(error)
    }
  }
  function verifyCamiCode() { }

  useEffect(() => {
    if (result) {
      let getUniqIdFromString = (text) => {
        try {
          const url = text.slice(-9);
          return url;
        } catch (error) {
          return "";
        }
      };
      const id = getUniqIdFromString(result);
      setUniqIdFromResult(id);
    }
  }, [result]);

  useEffect(() => {
    function getDecodedURL() {
      if (uniqIdFromResult) {
        let api =
          baseURL +
          "/api/CamiCode/GetCamiCodes_decodeURL?camicodeEncoded=" +
          uniqIdFromResult;
        const token = "Bearer " + localStorage.getItem("JWTTOKEN");
        fetch(api, {
          method: "GET",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
          .then((result) => {
            result
              .text()
              .then((value) => {
                setDecodeResponse(value);
                setFeedback({ status: "success", message: value });
                // navigate('/watch?' + value);
              })
              .catch((error) => {
                setFeedback({ status: "excecption", message: error });
              });
          })
          .catch((error) => {
            setFeedback({ status: "excecption", message: error });
          });
      }
    }
    getDecodedURL();
  }, [uniqIdFromResult]);

  function startScanning() {
    setFeedback({});
    try {
      const html5QrCode = new Html5Qrcode("reader", {
        formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
      });
      const qrCodeSuccessCallback = (decodedText, decodedResult) => {
        setResult(decodedText);
      };
      const config = { fps: 10, qrbox: { width: 250, height: 250 } };
      if (navigator.geolocation) {
        const handlePosition = (position) => {
          let latitude = position.coords.latitude;
          setLatitude(latitude);
          let longitude = position.coords.longitude;
          setLongitude(longitude);
        }
        navigator.geolocation.getCurrentPosition(handlePosition);
      }
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          const videoElement = document.getElementById('reader');
          videoElement.srcObject = stream;
          // Start QR code scanning after obtaining camera access.
          html5QrCode.start(
            { facingMode: "environment" },
            config,
            qrCodeSuccessCallback,
          );
          return () => {
            html5QrCode
              .stop()
              .then((ignore) => { })
              .catch((err) => {
              });
          };
        })
        .catch(function (error) {
          if (error.name === 'NotAllowedError') {
            // Handle the specific case where the user denied camera access.
            setCameraError(
              "Your browser settings does not allow CAMI application to use camera. Please update the settings."
            );
          } else {
            // Handle other errors

          }
        });

    } catch (error) {
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(decodeResponse);
    if (params.has("c")) {
      const c = params.get("c");
      setCamiCode(c);
    }
    if (params.has("p")) {
      const p = params.get("p");
      setPassword(p);
    }
  }, [decodeResponse]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return; // Skip the first render
    }
    getPaymentStatus();
    createLogDetails(logDate);
  }, [password, camiCode]);

  function get_url_extension(fileurl) {
    return fileurl.split(/[#?]/)[0].split(".").pop().trim();
  }

  function findlocation() {
    const _latlong = {
      camicode: camiCode,
      latitude: latitude,
      longitude: longitude,
    };
    // findlocationmapping(_latlong)
    // .then((response) => {
    //   // setCardData(response)
    // }).catch((error) => {
    //   // setCardData("false")
    // })
  }

  function getPaymentStatus() {
    if (password && camiCode) {
      let api = baseURL + "/api/CamiCode/VerifyCamiCode?";
      api += "camiCode=" + camiCode + "&uniqueId=" + password;
      const token = "Bearer " + localStorage.getItem("JWTTOKEN");
      fetch(api, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          let camiType = result["camiTypeId"];
          let _isPaid = result["isPaid"];
          let _videoDuration = result["duration"];
          let _video = result["videoId"];
          let _vEmail = result["vEmail"];
          let _vFileUrl = result["fileUrl"];
          let email = result["email"];
          let freeEmail = result['codeUser']
          if (_isPaid == true) {
            if (camiType == 5) {
              if (!_video) {
                if (user) {
                  console.log(foreverCami)
                  if (foreverCami > 0) {
                    if (email == user.email) {
                      navigate('/upload-video?c=' + camiCode + '&p=' + password);

                    } else {
                      setShowAlert(true)
                    }
                  } else {
                    setShowAlert(true)
                  }
                } else {
                  setShowAlert(true);
                  setLoginLink(true);
                }
              } else {

                navigate('/watch?c=' + camiCode + '&p=' + password + '&e=' + _vEmail + '&redir=' + encodeURIComponent('/camicard'));
              }
            }

            else if (camiType == 4) {
              if (!_video) {
                if (user) {
                  if (freeCami > 0) {
                    if (freeEmail == user.email) {
                      navigate('/upload-video?c=' + camiCode + '&p=' + password);
                    } else {
                      setShowAlert(true)
                    }
                  } else {
                    console.log('elsealert');
                    setShowAlert(true)
                  }
                } else {
                  setShowAlert(true);
                  setLoginLink(true);
                }
              } else {
                navigate('/watch?c=' + camiCode + '&p=' + password + '&e=' + _vEmail + '&redir=' + encodeURIComponent('/camicard'));
              }
            }
            else {
              if (!_video && !_vFileUrl) {
                if (_videoDuration == "29") {
                  localStorage.setItem('RT', 'P1');
                  // localStorage.removeItem('RT');
                } else if (_videoDuration == "180") {
                  localStorage.setItem('RT', 'P2');

                }
                navigate('/upload-video?c=' + camiCode + '&p=' + password);
                // window.location.reload();

              }
              else {
                localStorage.removeItem('RT');
                // localStorage.removeItem('color');
                // localStorage.removeItem('logo');

                navigate('/watch?c=' + camiCode + '&p=' + password + '&e=' + _vEmail + '&redir=' + encodeURIComponent('/camicard'));
              }

            }
          }

          else if (_isPaid == false) {
            console.log('IN scan page- paid - false')
          }
        }).catch((error) => {

          console.log(error);
        });
    }
  }
  useEffect(() => {
    verifyCamiCode();
    findlocation();
  }, [password, camiCode]);

  const handleCloseAlert = () => {
    window.location.reload();
  };

  function onCloseAlertBox() {
    setCameraError("");
    window.location.reload();
  }

  function renderErrorFeedBack() {
    if (CameraError) {
      return (
        <Box sx={{ margin: "auto", width: "60%", paddingTop: "60px" }}>
          <Alert severity={"error"} onClose={onCloseAlertBox}>
            {CameraError}
          </Alert>
        </Box>
      );
    }
  }

  function renderAlertFeedback() {
    if (feedback["status"]) {
      if (feedback["status"] === "excecption") {
        return (
          <Box sx={{ margin: "auto", width: "60%", paddingTop: "60px" }}>
            {" "}
            <Alert severity={"error"} onClose={onCloseAlertBox}>
              {feedback["message"]["message"]}
            </Alert>
          </Box>
        );
      }
    }
  }

  if (!showAlert) {
    return (
      <>
        <Box sx={{ paddingTop: smallDevices ? "60px" : "20px" }}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="h4"
                sx={{ fontWeight: 500, color: background.backgroundColor }}
                gutterBottom
                component="div"
              >
                Scan CAMIcodes
              </Typography>
            </Box>
          </Box>
        </Box>
        {renderAlertFeedback()}
        {renderErrorFeedBack()}
        <div
          ref={readerRef}
          style={{
            maxWidth: smallDevices ? window.width : "500px",
            maxHeight: smallDevices ? window.height : "500px",
            margin: "0 auto",
          }}
          id="reader"
        ></div>
      </>
    );
  } else {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "60vh",
            maxWidth: "100%",
          }}
        >
          <Alert onClose={handleCloseAlert} severity="info">
            There is no video currently uploaded to this CAMIcode
          </Alert>
        </Box>
        {loginLink == true && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: "10px",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "100%",
            }}
          >
            <Link
              to="/login"
              href="https://camicode.com/pages/privacy-policy"
              underline="none"
              color="#981D97"
              style={{
                padding: 0,
                borderColor: "#981D97",
                color: "blue",
                margin: "10px",
                alignItems: "center",
              }}
            >
              Registered CAMI user?Login here
            </Link>
            <Link
              to="/register"
              href="https://camicode.com/pages/privacy-policy"
              underline="none"
              color="#981D97"
              style={{
                borderColor: "#981D97",
                padding: 0,
                color: "blue",
                marginLeft: "20px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Not a registered CAMI user?Create an account
            </Link>
          </Box>
        )}
      </Box>
    );
  }
};

export default ScanCamiCode;

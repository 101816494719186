import { VideoUploader } from "@api.video/video-uploader";
import { Observable } from "rxjs";
import { checkVideoAvailability, getCamitagFromId, mapCamitagAndVideoApi } from "../Repository/Api";
import { generateUploadToken, getBearerToken } from "../Repository/ApiVideo";

export function getVideoConstraints(cameraFront) {
    if (cameraFront == true) {
        return {
            audio: {
                echoCancellation: true
            },
            video: {
                facingMode: 'user',

            }
        };

    }
    else if (cameraFront == false) {
        return {
            audio: {
                echoCancellation: true
            },
            video: {
                facingMode: 'environment',

            }
        };
    }

    else {
        return {
            audio: {
                echoCancellation: true
            },
            video: true
        };
    }
};


export const identifyFacingModeSupport = () => {
    try {
        const supports = navigator.mediaDevices.getSupportedConstraints();
        // return false;
        return supports.facingMode;
        // if (!supports['facingMode']) {
        //   
        //     return false;
        // }    
    } catch (error) {
       

        return false;
    }

}

export function requestMedia(_constraints = false) {
    return new Promise((resolve, reject) => {
        let CONSTRAINTS = (_constraints === false) ? getVideoConstraints() : _constraints;
        let MIMETYPE = getSupportedMedia();
        if (MIMETYPE === false) {
            reject({ type: 'exception', code: 'NULLMEDIARECORDER', message: 'Media recorder is not available in this Browser' });
        } else {
            CONSTRAINTS['mimeType'] = MIMETYPE;
           
            navigator.mediaDevices.getUserMedia(CONSTRAINTS)
                .then((stream) => {
                    const MR = new MediaRecorder(stream);
                    resolve({ type: 'success', code: 200, response: { streamObject: stream, mediaRecorder: MR } });
                })
                .catch((error) => {
                    reject({ type: 'exception', code: 'USERMEDIAERROR', message: 'User media exception', response: { errorObject: error } });
                });
        }
    });

}

export function getSupportedMedia() {
    if (!MediaRecorder) {
        return false;
    } else if (MediaRecorder.isTypeSupported("video/webm")) {
        return 'video/webm';
    } else if (MediaRecorder.isTypeSupported("video/mp4")) {
        return 'video/mp4';
    }
}

export let uploadRecordedVideoBusiness = (videoFile) => {
   
    return new Observable(observer => {
        getUploadToken()
            .then((delegate_token) => {
              
                const uploader = new VideoUploader({
                    file: videoFile,
                    uploadToken: delegate_token,
                    retries: 10
                });
                
                uploader.onProgress((event) => {
                    const uploadedPerc = (Number(event.uploadedBytes) / Number(event.totalBytes)) * 100;
                    observer.next({ event: 'progressbar', progress: uploadedPerc });
                });
                uploader.upload()
                    .then((video) => {
                        observer.next({ event: 'progressbar', progress: 100 });
                        observer.next({ event: 'complete', response: video });
                        observer.complete();
                    })
                    .catch((error) => {
                        observer.next({ event: 'error', msg: error });
                    });
            })
            .catch((err) => {
                observer.error({ event: 'error', msg: err });
                observer.complete();
            });
    });
}

export let getCamitagFromUrl = () => {
    return getQueryParamValue(window.location, 'c');
}

export let getRecordingTimeFromUrl = () => {
    return getQueryParamValue(window.location, 't');
}

export let getRecordingTimeFromLS = () => {
    if (localStorage.getItem('RT') == 'P1') {
        
        // localStorage.removeItem('RT');
        return 29 * 1000;
    } else if (localStorage.getItem('RT') == 'P2') {
       
        // localStorage.removeItem('RT');
        return 3 * 60 * 1000;
    } else {
        return 29000;
    }
}

export let getQueryParamValue = (url, paramName) => {
    try {
        const urlparams = new URLSearchParams(url.search);
        const _camitagid = urlparams.get(paramName) || "";
        return _camitagid;
    } catch (error) {
        return "";
    }
}

export let getUploadToken = () => {
    return new Promise((resolve, reject) => {
      
        getBearerToken()
            .then((res) => {
               
                if (res) {
                    generateUploadToken(res)
                        .then((tokenResult) => {
                            resolve(tokenResult['token']);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                } else {
                    reject({ msg: "Failed to fetch the bearer token" });
                }
            })
            .catch((err) => {
                reject({ msg: "Failed to fetch the bearer token", err: err });
            });
    })
}

export let getVideoAvailabilityStatus = (camitagId, password) => {
    return new Promise((resolve, reject) => {
        checkVideoAvailability(camitagId, password)
            .then((result) => {
                if (result['videoId'] || result['fileUrl']) {
                    resolve(formVCAResponse(result, 'ready'));
                } else {
                    result['camitag'] = camitagId;
                    resolve(formVCAResponse(result, 'navigate-camitagid'));
                }
            })
            .catch((err) => { });
    });
}

const formVCAResponse = (result, status) => {
    const _duration =result['duration'];
    if ( _duration == 29) {
        localStorage.setItem('RT','P1')
       
        // localStorage.removeItem('RT');
    } else if (_duration == 180) {
      
        localStorage.setItem('RT','P2')
        // localStorage.removeItem('RT');
    }    
    let res = {};
    if (status === 'navigate-camitagid') {
        res['url'] = "/upload-video?c=" + result['camitag'] + '&p=mediaBusiness';
        res['status'] = status;
        res['camiTypeId']=result["camiTypeId"];
        res["foreverEmail"]=result["foreverEmail"];
    } else if (status === 'navigate-fresh') {
        res['url'] = "/cami-steps";
        res['status'] = status;
    } else {
        res = JSON.parse(JSON.stringify(result));
        res['status'] = status;
    }
   
    return res;
}

export let mapCamitagAndVideo = (data) => {
    return new Promise((resolve, reject) => {
        mapCamitagAndVideoApi(data)
            .then((res) => res.json())
            .then((result) => {
               
                let CAMICode = result.camiCode;
                let UniqueId = result.uniqueId;
              
                resolve({ "camiId": CAMICode, "camiPass": UniqueId });

            })
            .catch((error) => {
                reject(error);
            });

    });
}

export const downloadFile = url => window.open(url, '_blank');

const Exports = {
    getVideoConstraints,
    requestMedia,
    getSupportedMedia,
    requestMedia,
    uploadRecordedVideoBusiness,
    getCamitagFromUrl,
    getRecordingTimeFromUrl,
    getRecordingTimeFromLS,
    getQueryParamValue,
    getUploadToken,
    mapCamitagAndVideo,
    downloadFile
};
export default Exports;

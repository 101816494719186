import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo-purple-horizontal.png";
import logoWhite from "../../assets/images/logo-white-horizontal.png";
import { signOut } from '../../Business/AuthenticationBusiness';
import { SwipeableDrawer } from '@mui/material';
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { Typography } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import { getUserByAuthId, getUserByEmail, getUserByEmailAuthId, getUserById } from '../../Repository/Api';
import { getFirebaseAuthModule } from '../../Repository/Authentication/Firebase';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';


export default function Drawer(props) {

    const defaultColor = "#981D97";
    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openCreateCamiDrawer, setOpenCreateCamiDrawer] = useState(false);
    const [state, setState] = useState(false);
    const [openMyAccountDrawer, setOpenMyAccountDrawer] = useState(false);
    const [openProfileDrawer, setOpenProfileDrawer] = useState(false);
    const [dark, setDark] = useState(true);
    const [login, setLogin] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [apiData, setApiData] = useState('');
    const [logoutState, setLogout] = useState([0])
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const [isLoading, setIsLoading] = useState(false);
    const [freeCamiExist, setFreeCamiExist] = useState(false);
    const [camiforeverExist, setCamiforeverExist] = useState(false);

    function circularIndeterminate() {
        return (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box>
        );
    }

    useEffect(() => {
        const auth = getFirebaseAuthModule();
        auth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken().then((newToken) => {
                    localStorage.setItem("JWTTOKEN", newToken);
                    // fetchData(user.email);
                    getFreeCami(user.uid)

                });
            } else {
                return ((error => {

                }))
            }

        }
        );
    }, []);
    const getFreeCami = async (user) => {
        setIsLoading(true);
        const res = await getUserByAuthId(user)
            .then(response => {
                setApiData(response.userPhoto);
                setIsLoading(false);
                if (response['freeCamiCodes']) {
                    if (response['freeCamiCodes'] > 0) {
                        setFreeCamiExist(true)
                    } else {
                        setFreeCamiExist(false)
                    }
                }
                if (response['foreverCamiCodes'] > 0) {
                    setCamiforeverExist(true)
                } else {
                    setCamiforeverExist(false)
                }
            }).catch(error => {

            })
    }

    useEffect(() => { setDark(props.dark); }, [props.dark]);
    useEffect(() => { setLogin(props.login); }, [props.login]);
    useEffect(() => { setState(props.state); }, [props.state]);

    const subMenuTextStyle = {
        fontSize: 14
    }
    function handleClickMyAccount(event) {
        setOpenMyAccountDrawer(!openMyAccountDrawer);
    }
    function handleClick(event) {
        setOpenDrawer(!openDrawer);
    }
    function handleClickCreateCami(event) {
        setOpenCreateCamiDrawer(!openCreateCamiDrawer);
    }
    const handleProfileMenuOpen = (event) => {
        setOpenProfileDrawer(!openProfileDrawer);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };
    function signOutAction() {
        const status = signOut();
        navigate('/login');
    }
    function toggleDrawer(openDrawer) {
        return function (event) {
            if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
                return;
            }
            setState(openDrawer);
        }
    }
    function list() {
        return (
            <Box
                sx={{ width: 250 }}
                role="presentation"
                onKeyDown={toggleDrawer(false)}>
                <List>
                    {getMenuList()}
                </List>
            </Box>
        );
    }
    function getMenuList() {
        let linkColor = (dark) ? ({ textDecoration: "none", color: "white" }) : ({ marginHorizontal: 2, color: "#981d97" });
        if (login) {
            return (
                <>
                    {nestedListProfileDrawer()}
                    {nestedListMyCamiCodeSubMenuDrawer()}
                    {nestedListMyAccountSubMenuDrawer()}
                    <a href="https://camicode.com/pages/about-cami"
                        style={{
                            color: "#981d97",
                            textDecoration: "none",
                            paddingLeft: 20
                        }}
                    >
                        <ListItem button
                            sx={{ marginHorizontal: 2, color: "#981d97", marginTop: -5 }} >
                            <ListItemText primary="About CAMI" sx={{ paddingLeft: 1 }} />
                        </ListItem>
                    </a>
                </>
            );
        } else {
            return (
                <>
                    <List
                    >
                        {nestedListCreateCamiCodeSubMenuDrawer()}
                        <Link
                            style={{ textDecoration: "none", color: "white" }}
                            to="/login"  >
                            <ListItem button
                                onClick={toggleDrawer(false)}
                                sx={{ marginHorizontal: 2, color: "#981D97", marginTop: -3 }} >
                                <ListItemText primary="Login" sx={{ paddingLeft: 1 }} />
                            </ListItem>
                        </Link>
                        <Link
                            style={{ textDecoration: "none", color: "white" }}
                            to="/register"  >
                            <ListItem button
                                onClick={toggleDrawer(false)}
                                sx={{ marginHorizontal: 2, color: "#981D97", marginTop: -1 }} >
                                <ListItemText primary="Register" sx={{ paddingLeft: 1 }} />
                            </ListItem>
                        </Link>
                        <a href="https://camicode.com/pages/about-cami"
                            style={{
                                color: "#981D97",
                                textDecoration: "none",
                                paddingLeft: 20
                            }}
                        >
                            <ListItem button
                                sx={{ marginHorizontal: 2, color: "#981D97", marginTop: -4 }} >
                                <ListItemText primary="About CAMI" sx={{ paddingLeft: 1 }} />
                            </ListItem>
                        </a>
                    </List>
                </>
            );
        }
    }
    function nestedListCreateCamiCodeSubMenuDrawer() {
        return (
            <>
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', color: defaultColor }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"  >

                    <Box sx={{ flexGrow: 5, display: "flex", paddingTop: 1, marginTop: -1 }}>
                        <a href="https://camicode.com" underline="none" >
                            <img src={logo} style={{ height: 30, paddingLeft: 15 }}></img>
                        </a>
                    </Box>

                    <ListItemButton onClick={handleClickCreateCami} >
                        <ListItemText primary="CAMIcodes" sx={{ paddingLeft: 1 }} />
                        {openCreateCamiDrawer ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                        in={openCreateCamiDrawer}
                        timeout="auto"
                        unmountOnExit>
                        <List
                            component="div"
                            disablePadding
                            onClick={toggleDrawer(false)}>
                            <Link to="cami-steps"
                                style={{ textDecoration: "none", color: defaultColor }} >
                                <ListItemButton sx={{ pl: 4, marginTop: -2 }} onClick={toggleDrawer(false)}>
                                    <ListItemText primary="Create CAMIcode" />
                                </ListItemButton>
                            </Link>
                            <Link to="scan-cami"
                                style={{ textDecoration: "none", color: defaultColor }} >
                                <ListItemButton sx={{ pl: 4, marginTop: -2 }} onClick={toggleDrawer(false)}>
                                    <ListItemText primary="Scan CAMIcode" />
                                </ListItemButton>
                            </Link>
                        </List>
                    </Collapse>
                </List>
            </>
        )
    }
    function nestedListMyCamiCodeSubMenuDrawer() {
        return (
            <>
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', color: defaultColor, marginBottom: 2 }}
                    component="nav"
                    aria-labelledby="nested-list-subheader">
                    <ListItemButton onClick={handleClick} sx={{ marginTop: -4 }}>
                        <ListItemText primary="My CAMIcodes" sx={{ paddingLeft: 1 }} />
                        {openDrawer ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                        in={openDrawer}
                        timeout="auto"
                        unmountOnExit>
                        <List
                            component="div"
                            disablePadding
                        >
                            <Link to="created-camicode"
                                style={{ textDecoration: "none", color: defaultColor }} >
                                <ListItemButton sx={{ pl: 4, marginTop: -1 }} onClick={toggleDrawer(false)}>
                                    <ListItemText primary="Created CAMIcodes" />
                                </ListItemButton>
                            </Link>
                            <Link to="received-camicode"
                                style={{ textDecoration: "none", color: defaultColor }} >
                                <ListItemButton sx={{ pl: 4, marginTop: -1 }} onClick={toggleDrawer(false)}>
                                    <ListItemText primary="Received CAMIcodes" />
                                </ListItemButton>
                            </Link>
                        </List>
                    </Collapse>
                </List>
            </>
        )
    }
    function nestedListProfileDrawer() {
        return (
            <>
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', color: defaultColor }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader
                            component="div"
                            id="nested-list-subheader"
                            sx={{ textAlign: "center" }} >
                            <Box sx={{ flexGrow: 5, display: "flex", justifyContent: "space-between", marginTop: 1 }}>
                                <a href="https://camicode.com" underline="none" >
                                    <img src={logo} style={{ height: 30, display: "flex" }}></img>
                                </a>
                                {isLoading == true && circularIndeterminate()}
                                {isLoading == false && <Avatar src={apiData} alt="Profile_pic" onClick={handleProfileMenuOpen} />}
                            </Box>
                            <Paper elevation={0} style={{ paddingLeft: 2 }}>
                                <Collapse in={openProfileDrawer} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding >
                                        <Link to="my-profile" style={{ textDecoration: "none", color: defaultColor }} >
                                            <ListItemButton sx={{ pl: 3, marginTop: -2 }} onClick={toggleDrawer(false)}>
                                                <ListItemText primary="My Profile" />
                                            </ListItemButton>
                                        </Link>
                                        <ListItem button
                                            sx={{ marginHorizontal: 2, color: "#981D97", marginTop: -1 }}
                                            onClick={toggleDrawer(false)} >
                                            <ListItemText
                                                onClick={signOutAction}
                                                style={{ color: defaultColor, textTransform: "capitalize", paddingLeft: "10px", }}
                                                primary="Logout" />
                                        </ListItem>

                                    </List>
                                </Collapse>
                            </Paper>
                        </ListSubheader>
                    }></List>
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', color: defaultColor, marginBottom: 1 }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader
                            component="div"
                            id="nested-list-subheader"
                            sx={{ textAlign: "center" }} >
                        </ListSubheader>
                    }>
                    <ListItemButton onClick={handleClickCreateCami} sx={{ marginTop: -1 }}>
                        <ListItemText primary="CAMIcodes" sx={{ paddingLeft: 1, paddingTop: 1 }} />
                        {openCreateCamiDrawer ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                        in={openCreateCamiDrawer}
                        timeout="auto"
                        unmountOnExit>
                        <List
                            component="div"
                            disablePadding
                            onClick={toggleDrawer(false)}>
                            <Link to="cami-steps"
                                style={{ textDecoration: "none", color: defaultColor }} >
                                <ListItemButton sx={{ pl: 4, marginTop: -1 }}>
                                    <ListItemText primary="Create CAMIcode" />
                                </ListItemButton>
                            </Link>
                            <Link to="scan-cami"
                                style={{ textDecoration: "none", color: defaultColor }} >
                                <ListItemButton sx={{ pl: 4, marginTop: -1 }}>
                                    <ListItemText primary="Scan CAMIcode" />
                                </ListItemButton>
                            </Link>
                        </List>
                    </Collapse>
                </List>
            </>
        )
    }
    function nestedListMyAccountSubMenuDrawer() {
        return (
            <>
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', color: defaultColor, marginTop: -3 }}
                    component="nav"
                    aria-labelledby="nested-list-subheader">
                    <ListItemButton onClick={handleClickMyAccount} sx={{ marginTop: -2 }}>
                        <ListItemText primary="My Account" sx={{ paddingLeft: 1 }} />
                        {openMyAccountDrawer ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openMyAccountDrawer} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding >
                            <Link to="my-payment-methods" style={{ textDecoration: "none", color: defaultColor }} >
                                <ListItemButton sx={{ pl: 4, marginTop: -1 }} onClick={toggleDrawer(false)}>
                                    <ListItemText primary="My Payment Method" />
                                </ListItemButton>
                            </Link>
                            <Link to="my-transaction" style={{ textDecoration: "none", color: defaultColor }} >
                                <ListItemButton sx={{ pl: 4, marginTop: -1 }} onClick={toggleDrawer(false)}>
                                    <ListItemText primary="My Transaction" />
                                </ListItemButton>
                            </Link>
                            {freeCamiExist == true && <Link to="/free-cami" style={{ textDecoration: "none", color: defaultColor }} >
                                <ListItemButton sx={{ pl: 4, marginTop: -1 }} onClick={toggleDrawer(false)}>
                                    <ListItemText primary="Free CAMIcodes" />
                                </ListItemButton>
                            </Link>}
                            {camiforeverExist == true && <Link to="forever-cami" style={{ textDecoration: "none", color: defaultColor }} >
                            <ListItemButton sx={{ pl: 4, marginTop: -1 }} onClick={toggleDrawer(false)}>
                                    <ListItemText primary="CAMI-Forever"/>
                                </ListItemButton>
                            </Link>}
                        </List>
                    </Collapse>
                </List>
            </>
        )
    }
    return (
        <div>
            <SwipeableDrawer
                anchor={"right"}
                open={state}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}>
                {list()}
            </SwipeableDrawer>
        </div>
    )
}